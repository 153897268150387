// Logout.js

import React, { useState, useEffect } from 'react';
import {
  getAuth,
  updateProfile,
  deleteUser,
  signOut,
} from 'firebase/auth';
import { db, storage } from '../firebase';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, setDoc, deleteDoc, } from 'firebase/firestore';
import '../styles/Logout.css'; // Import the CSS file
import { ToastContainer, toast } from 'react-toastify'; // For notifications
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profileImage, setProfileImage] = useState('/default-profile.png');
  const [newImage, setNewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showDeleteModal, setShowDeleteModal] = useState(false); // For delete confirmation

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        setName(firebaseUser.displayName || '');
        setEmail(firebaseUser.email || '');
        setProfileImage(firebaseUser.photoURL || '/default-profile.png');
      } else {
        setUser(null);
        // Redirect to login page if the user is not authenticated
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  if (user === null) {
    // Optionally, you can show a loading indicator here
    return <div>Loading...</div>;
  }

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setNewImage(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleProfileImageClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let photoURL = user.photoURL;
      if (newImage) {
        if (user.photoURL) {
          const oldImageRef = ref(storage, `profiles/${user.uid}`);
          await deleteObject(oldImageRef).catch((error) => {
            console.error('Error deleting old profile image:', error);
          });
        }
        const imageRef = ref(storage, `profiles/${user.uid}`);
        await uploadBytes(imageRef, newImage);
        photoURL = await getDownloadURL(imageRef);
      }

      // Update the user's profile in Firebase Authentication
      await updateProfile(user, { displayName: name, photoURL });

      // Use setDoc with { merge: true } to create or update the user document in Firestore
      await setDoc(
        doc(db, 'users', user.uid),
        { displayName: name, photoURL }, // Removed jobTitle here
        { merge: true }
      );

      // Re-fetch the user object to ensure it's up-to-date
      const updatedUser = auth.currentUser;
      setUser(updatedUser);

      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(`Error updating profile: ${error.message}`);
    }
    setIsLoading(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Error signing out');
    }
  };

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      // Delete user document from Firestore
      await deleteDoc(doc(db, 'users', user.uid));
      // Delete profile image from Storage
      if (user.photoURL) {
        const imageRef = ref(storage, `profiles/${user.uid}`);
        await deleteObject(imageRef);
      }
      // Delete user authentication
      await deleteUser(user);
      toast.success('Account deleted successfully');
      navigate('/signup'); // Redirect to signup page after account deletion
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error('Error deleting account');
    }
    setIsLoading(false);
    setShowDeleteModal(false);
  };

  return (
    <Container fluid className="logout-container">
      <Row>
        {/* Left Column */}
        <Col md={4} className="left-column">
          <h2 className="logout-title">My Profile</h2>
          <div
            className="logout-profile-image-container"
            onClick={handleProfileImageClick}
          >
            <img
              src={profileImage || '/default-profile.png'}
              alt="Profile"
              className="logout-profile-image"
            />
            <div className="overlay">
              <div className="overlay-text">Change Image</div>
            </div>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </div>
          <h3 className="mt-3">{name}</h3>
          <p>{email}</p>
        </Col>
        {/* Vertical Divider */}
        <Col md={1} className="d-none d-md-block">
          <div className="vertical-divider"></div>
        </Col>
        {/* Right Column */}
        <Col md={7}>
          <Form className="logout-form" onSubmit={handleProfileUpdate}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                className="login-form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </Form.Group>
            {/* Removed Job Title Section */}
            {/* Buttons */}
            <div className="button-group mt-4">
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading}
                className="me-2"
              >
                {isLoading ? 'Updating...' : 'Update Profile'}
              </Button>
              <Button
                variant="secondary"
                onClick={handleLogout}
                className="me-2"
              >
                Logout
              </Button>
              <Button
                variant="danger"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete Account
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

      {/* Delete Account Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Account Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete your account? This action cannot
            be undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteAccount}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Delete Account'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Container>
  );
};

export default Logout;
