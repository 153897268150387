import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../logo.webp';  // Ensure to update the path to your logo
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);

    // Manually trigger the collapse of the Bootstrap menu
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse) {
      new window.bootstrap.Collapse(navbarCollapse, {
        toggle: false,
      }).hide();
    }
  };

  return (
    <header className="header-navbar navbar navbar-expand-lg">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={logo} alt="Imagine Seniorcare Inc Logo" className="logo-button" />
        </NavLink>
        <button 
          className={`navbar-toggler ${isOpen ? 'open' : ''}`} 
          type="button" 
          onClick={handleToggle}
          aria-controls="navbarNav" 
          aria-expanded={isOpen} 
          aria-label="Toggle navigation"
          data-toggle="collapse" 
          data-target="#navbarNav"
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink exact className="nav-link" activeClassName="active" to="/" onClick={handleClose}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/services" onClick={handleClose}>Our Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/about" onClick={handleClose}>About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/contact" onClick={handleClose}>Contact</NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link btn btn-login"
                to="/login"
                onClick={(e) => {
                  e.preventDefault();
                  window.open('/login', '_blank', 'noopener,noreferrer');
                }}
              >
                Login
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
