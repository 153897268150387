// src/components/timesheets/timesheetPDF.js

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../../images/logo.webp'; // Adjust the path as necessary

// Generate Timesheet PDF for Individual Employee
const generateTimesheetPDF = async ({
  companyInfo,
  timesheetData,
  employeeName,
  notes,
  overtimeRate,
  includeDetailedLogs,
}) => {
  const doc = new jsPDF();
  const primaryColor = '#D77124'; // Adjust as needed
  const margin = 20;

  // Add logo and company information
  doc.addImage(logo, 'WEBP', margin, 10, 50, 25);
  doc.setFontSize(16);
  doc.setTextColor(primaryColor);
  doc.text(companyInfo.name, margin + 60, 15);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text(`Email: ${companyInfo.email} | Phone: ${companyInfo.phone}`, margin + 60, 22);
  doc.text(`Website: ${companyInfo.website}`, margin + 60, 29);
  doc.text(companyInfo.address, margin + 60, 36);

  // Divider line
  doc.setDrawColor(primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

  // Timesheet Title
  doc.setFontSize(20);
  doc.setTextColor(primaryColor);
  doc.text('Timesheet', margin, 55);

  // Employee Details and Summary at the Top
  const employeeDetailsData = [
    ['Employee Name', employeeName],
    ['Pay Period', `${timesheetData.startDate || 'N/A'} - ${timesheetData.endDate || 'N/A'}`],
    ['Notes', notes || 'N/A'],
    ['Total Hours Worked', `${timesheetData.totalHours.toFixed(2)} hrs`],
    [`Total Overtime (${overtimeRate})`, `${timesheetData.totalOvertime.toFixed(2)} hrs`],
    ['Hourly Wage', `$${timesheetData.hourlyRate.toFixed(2)}`],
    ['Total Pay', `$${timesheetData.totalPay.toFixed(2)}`],
  ];

  autoTable(doc, {
    startY: 65,
    margin: { left: margin },
    body: employeeDetailsData,
    theme: 'striped',
    styles: { fontSize: 12, cellPadding: 5, textColor: 0 },
    columnStyles: {
      0: { fontStyle: 'bold', halign: 'left', cellWidth: 60 },
      1: { halign: 'left' },
    },
  });

  // Add a new page for detailed logs if requested
  if (includeDetailedLogs) {
    doc.addPage();

    autoTable(doc, {
      startY: margin,
      head: [['Date', 'Clock In', 'Clock Out', 'Break Time', 'Overtime', 'Total Hours']],
      body: [
        ...timesheetData.entries.map((entry) => [
          entry.date,
          entry.clockIn,
          entry.clockOut,
          `${entry.breakTime || 0} min`,
          `${entry.overtime || 0} hrs`,
          `${entry.totalHours.toFixed(2)} hrs`,
        ]),
        // Total row at the bottom of logs
        [
          { content: 'Total', colSpan: 4, styles: { halign: 'right', fontStyle: 'bold' } },
          `${timesheetData.totalOvertime.toFixed(2)} hrs`,
          `${timesheetData.totalHours.toFixed(2)} hrs`,
        ],
      ],
      theme: 'grid',
      headStyles: { fillColor: primaryColor, textColor: [255, 255, 255] },
      styles: { fontSize: 11, cellPadding: 5, textColor: 0 },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 30 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
        4: { cellWidth: 30 },
        5: { cellWidth: 30 },
      },
    });
  }

  // Save the PDF
  doc.save('employee-timesheet.pdf');
};

export { generateTimesheetPDF };
