// src/components/timesheets/summaryPDF.js

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../../images/logo.webp'; // Adjust the path as necessary

// Generate Summary PDF for All Employees
const generateSummaryPDF = async ({
  companyInfo,
  aggregatedData,
  timesheetData,
  notes,
  overtimeRate,
  includeDetailedLogs,
}) => {
  const doc = new jsPDF();
  const primaryColor = '#D77124';
  const margin = 20;

  // Add logo and company information
  doc.addImage(logo, 'WEBP', margin, 10, 50, 25);
  doc.setFontSize(16);
  doc.setTextColor(primaryColor);
  doc.text(companyInfo.name, margin + 60, 15);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text(`Email: ${companyInfo.email} | Phone: ${companyInfo.phone}`, margin + 60, 22);
  doc.text(`Website: ${companyInfo.website}`, margin + 60, 29);
  doc.text(companyInfo.address, margin + 60, 36);

  // Divider line
  doc.setDrawColor(primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

  // Report Title and Details
  doc.setFontSize(20);
  doc.setTextColor(primaryColor);
  doc.text('Employee Payment Summary', margin, 55);

  // Pay Period Details and Notes
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text(`Pay Period: ${timesheetData.startDate} - ${timesheetData.endDate}`, margin, 65);
  if (notes) {
    const splitNotes = doc.splitTextToSize(`Notes: ${notes}`, 170);
    doc.text(splitNotes, margin, 73);
  }

  // Summary Table
  autoTable(doc, {
    startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 8 : 85,
    head: [
      [
        'Employee Name',
        'Total Hours Worked',
        `Overtime (${overtimeRate}) (hrs)`,
        'Hourly Wage ($)',
        'Total Pay ($)',
      ],
    ],
    body: aggregatedData.map((emp) => [
      emp.employeeName,
      `${emp.totalHours} hrs`,
      `${emp.totalOvertime} hrs`,
      `$${emp.ratePerHour}`,
      `$${emp.totalPay}`,
    ]),
    theme: 'grid',
    headStyles: { fillColor: primaryColor, textColor: [255, 255, 255] },
    styles: { fontSize: 11, cellPadding: 5, textColor: 0 },
  });

  let finalY = doc.lastAutoTable.finalY + 10;

  // Display Detailed Logs if Requested
  if (includeDetailedLogs) {
    aggregatedData.forEach((emp) => {
      doc.addPage();
      doc.setFontSize(16);
      doc.setTextColor(primaryColor);
      doc.text(`Detailed Logs for ${emp.employeeName}`, margin, 20);

      autoTable(doc, {
        startY: 30,
        head: [['Date', 'Clock In', 'Clock Out', 'Break Time', 'Overtime', 'Total Hours']],
        body: emp.timesheets.map((log) => [
          log.date || 'N/A',
          log.clockIn || 'N/A',
          log.clockOut || 'N/A',
          `${log.breakTime || 0} min`,
          `${log.overtime || 0} hrs`,
          `${log.hoursWorked.toFixed(2)} hrs`,
        ]),
        theme: 'grid',
        headStyles: { fillColor: primaryColor, textColor: [255, 255, 255] },
        styles: { fontSize: 11, cellPadding: 5, textColor: 0 },
      });

      // Display Total Hours and Total Pay for each employee at the end of their logs
      const totalHours = emp.timesheets.reduce((sum, log) => sum + log.hoursWorked, 0).toFixed(2);
      const totalPay = (totalHours * emp.ratePerHour).toFixed(2);

      autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 10,
        body: [
          [{ content: 'Total Hours:', styles: { fontStyle: 'bold' } }, { content: `${totalHours} hrs`, styles: { fontStyle: 'bold' } }],
          [{ content: 'Total Pay:', styles: { fontStyle: 'bold' } }, { content: `$${totalPay}`, styles: { fontStyle: 'bold' } }],
        ],
        theme: 'plain',
        styles: { fontSize: 12, cellPadding: 5, textColor: 0 },
        columnStyles: {
          0: { halign: 'left', cellWidth: 60 },
          1: { halign: 'left' },
        },
      });
    });
  }

  // Grand Total Summary at the End
  const grandTotalHours = aggregatedData.reduce((sum, emp) => sum + parseFloat(emp.totalHours), 0).toFixed(2);
  const grandTotalPay = aggregatedData.reduce((sum, emp) => sum + parseFloat(emp.totalPay), 0).toFixed(2);

  // Position of the grand totals section
const totalYPosition = finalY + 10;

// Set font size and color
doc.setFontSize(14);
doc.setTextColor(0, 0, 0);

// Draw "Total Hours:" label in bold
doc.setFont("helvetica", "bold");
doc.text("Total Hours:", margin, totalYPosition);

// Draw "Total Hours" value in regular font, right next to the label
doc.setFont("helvetica", "normal");
doc.text(`${grandTotalHours} hrs`, margin + 30, totalYPosition); // Adjust `+ 30` as needed for spacing

// Draw "Total Pay:" label in bold, slightly below "Total Hours"
doc.setFont("helvetica", "bold");
doc.text("Total Pay:", margin, totalYPosition + 8);

// Draw "Total Pay" value in regular font, right next to the label
doc.setFont("helvetica", "normal");
doc.text(`$${grandTotalPay}`, margin + 25, totalYPosition + 8); // Adjust `+ 30` as needed for spacing


  // Save the PDF
  doc.save('employee-summary.pdf');
};

export { generateSummaryPDF };
