import React, { useState } from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { storage, db } from "../../firebase"; 

const UploadFile = ({ fetchData, folders }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [customFileName, setCustomFileName] = useState(""); // State for custom file name

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (!file) return toast.error("Please select a file!");
  
    const fileName = customFileName || file.name; // Use custom file name if provided, otherwise original name
    const storageRef = ref(storage, `files/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(prog);
      },
      (error) => toast.error("Error uploading file: " + error.message),
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        try {
          const fileData = {
            fileName,
            url,
            mimeType: file.type, // Capture the MIME type
            isFolder: false,
            folderId: selectedFolder || "main", // Default to main if no folder selected
            uploadDate: new Date().toISOString(), // Include upload date
          };
  
          await addDoc(collection(db, "applications"), fileData);
          toast.success("File uploaded successfully!");
          setFile(null);
          setProgress(0);
          setShowModal(false);
          setCustomFileName(""); // Reset custom file name after upload
          fetchData(); // Refresh data after upload
        } catch (error) {
          toast.error("Error saving file data: " + error.message);
        }
      }
    );
  };
  

  return (
    <>
      {/* Upload button to open modal */}
      <Button
        className="filter-button ml-2 dashboard-add-client-btn custom-width-button"
        onClick={() => setShowModal(true)}
        variant="primary"
        >
        + Upload Application
      </Button>

      {/* Modal for file upload */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={handleFileUpload}>
          <Modal.Header closeButton className="employee-log-modal-header">
            <Modal.Title className="employee-log-modal-title">Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body className="employee-log-modal-body">
            {/* Progress bar for upload progress */}
            {progress > 0 && (
              <ProgressBar now={progress} label={`${progress}%`} className="mb-3" />
            )}

            {/* File selection input */}
            <Form.Group>
              <Form.Label>Select File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                required
                className="same-input-style" // Ensure consistent styles
              />
            </Form.Group>

            {/* Custom file name input */}
            <Form.Group className="mt-3">
              <Form.Label>Application Name (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a custom file name"
                value={customFileName}
                onChange={(e) => setCustomFileName(e.target.value)} // Set custom file name
                className='all-modal-form-control'// Ensure consistent styles
              />
            </Form.Group>

            {/* Folder selection dropdown */}
            <Form.Group className="mt-3">
              <Form.Label>Select Folder</Form.Label>
              <Form.Select
                value={selectedFolder}
                onChange={(e) => setSelectedFolder(e.target.value)}
                className="same-input-style" // Ensure consistent styles
              >
                <option value="">Main Screen</option> {/* No folder means Main Screen */}
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>
                    {folder.folderName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="employee-log-modal-footer">
            {/* Close button to close the modal */}
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            {/* Submit button to trigger file upload */}
            <Button
              type="submit"
              variant="primary"
              disabled={!file || progress > 0}
            >
              Upload Application
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UploadFile;
