import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import '../styles/ContactForm.css';

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xanwjgyz");

  return (
    <div className="container-fluid contact-form-container">
      <div className="row no-gutters">
        <div className="col-md-6 form-content">
          <h2>Fill Out The Form And We’ll Get In Touch:</h2>
          <p>Where Your Loved Ones are Cherished, Respected, and Thriving Every Day</p>
        </div>
        <div className="col-md-6">
          {state.succeeded ? (
            <div className="contact-form success-message">
              <h2>Successfully Submitted</h2>
              <p>Thank you for reaching out to us.<br>
              </br> We will get back to you shortly.</p>
            </div>
          ) : (
            <Form onSubmit={handleSubmit} className="contact-form">
              <Row>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label htmlFor="firstName">Your First name</Form.Label>
                    <Form.Control type="text" id="firstName" name="firstName" placeholder="Jane" required />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label htmlFor="lastName">Your Last name</Form.Label>
                    <Form.Control type="text" id="lastName" name="lastName" placeholder="Doe" required />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label htmlFor="email">Your Email address</Form.Label>
                    <Form.Control type="email" id="email" name="email" placeholder="janedoe@email.com" required />
                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label htmlFor="subject">Subject</Form.Label>
                    <Form.Control type="text" id="subject" name="subject" placeholder="Enter subject" required />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="message">Your message</Form.Label>
                <Form.Control as="textarea" id="message" name="message" rows={5} placeholder="Enter your question or message" required />
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </Form.Group>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button variant="primary" type="submit" className="btn btn-submit" disabled={state.submitting}>
                    Send Message
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
