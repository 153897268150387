// src/components/Owners.js
import React from 'react';
import '../styles/OwnersBar.css'; // Make sure to create this CSS file

const Owners = () => {
  return (
    <div className="owners-bar">
      <h2>MEET THE OWNERS</h2>
    </div>
  );
};

export default Owners;