import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
  Form,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Card,
  Spinner,
} from 'react-bootstrap';
import {
  collection,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
  onSnapshot,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../firebase';
import generatePDF from './generatePDF';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faCalendarAlt, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import "../styles/Invoices.css";

// Helper function to validate date strings.
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

// Create Invoice Modal Component
const CreateInvoiceModal = ({ show, onClose, onInvoiceCreated }) => {
  const [clientName, setClientName] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [stayDuration, setStayDuration] = useState({ start: '', end: '' });
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (show) {
      generateInvoiceNumber();
      resetFields();
    }
  }, [show]);

  const resetFields = () => {
    setClientName('');
    setClientAddress('');
    setStayDuration({ start: '', end: '' });
    setInvoiceAmount('');
    setDescription('');
  };

  const generateInvoiceNumber = () => {
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`;
    const randomFourDigit = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
    const invoiceNum = `${formattedDate}-${randomFourDigit}`;
    setInvoiceNumber(invoiceNum);
  };
  

  const handleSaveInvoice = async () => {
    if (!clientName || !invoiceAmount || isNaN(invoiceAmount)) {
      toast.error('Please enter a valid client name and invoice amount.');
      return;
    }

    if (
      (stayDuration.start && !isValidDate(stayDuration.start)) ||
      (stayDuration.end && !isValidDate(stayDuration.end))
    ) {
      toast.error('Please enter valid start and end dates.');
      return;
    }

    try {
      const newInvoice = {
        clientName: clientName.trim(),
        clientAddress: clientAddress.trim(),
        stayDuration: {
          start: stayDuration.start ? new Date(stayDuration.start).toISOString() : null,
          end: stayDuration.end ? new Date(stayDuration.end).toISOString() : null,
        },
        invoiceAmount: parseFloat(invoiceAmount),
        invoiceNumber,
        createdAt: new Date().toISOString(),
        description: description.trim() || 'Services Rendered',
        status: 'Unpaid',
      };

      await addDoc(collection(db, 'invoices'), newInvoice);
      generatePDF(newInvoice);
      toast.success('Invoice created and saved successfully!');
      onInvoiceCreated();
      onClose();
    } catch (error) {
      console.error('Error saving invoice:', error);
      toast.error('Failed to save invoice.');
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onClose} centered>
      <Modal.Header className="employee-log-modal-header" closeButton>
        <Modal.Title className="employee-log-modal-title">Create New Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="employee-log-modal-body">
        <Form className="employee-log-form">
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="clientName">
                <Form.Label>Client's Name</Form.Label>
                <Form.Control
                  type="text"
                  className='all-modal-form-control'
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  placeholder="Enter client's name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="clientAddress">
                <Form.Label>Client's Address</Form.Label>
                <Form.Control
                  type="text"
                  className='all-modal-form-control'
                  value={clientAddress}
                  onChange={(e) => setClientAddress(e.target.value)}
                  placeholder="Enter client's address"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="stayStart">
                <Form.Label>Start Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.start}
                  onChange={(e) => setStayDuration({ ...stayDuration, start: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="stayEnd">
                <Form.Label>End Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.end}
                  onChange={(e) => setStayDuration({ ...stayDuration, end: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="invoiceAmount">
                <Form.Label>Invoice Amount</Form.Label>
                <Form.Control
                  type="number"
                  className='all-modal-form-control'
                  value={invoiceAmount}
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                  placeholder="Enter invoice amount"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="invoiceNumber">
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control
                  type="text"
                  className='all-modal-form-control'
                  value={invoiceNumber}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className='all-modal-form-control'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description for services rendered"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="employee-log-modal-footer">
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={handleSaveInvoice}>Create Invoice</Button>
      </Modal.Footer>
    </Modal>
  );
};

// Edit Invoice Modal Component
const EditInvoiceModal = ({ show, onClose, invoice, onInvoiceUpdated }) => {
  const [clientName, setClientName] = useState(invoice?.clientName || '');
  const [clientAddress, setClientAddress] = useState(invoice?.clientAddress || '');
  const [stayDuration, setStayDuration] = useState({
    start: invoice?.stayDuration?.start ? new Date(invoice.stayDuration.start).toISOString().split('T')[0] : '',
    end: invoice?.stayDuration?.end ? new Date(invoice.stayDuration.end).toISOString().split('T')[0] : '',
  });
  const [invoiceAmount, setInvoiceAmount] = useState(invoice?.invoiceAmount || '');
  const [status, setStatus] = useState(invoice?.status || 'Unpaid');
  const [description, setDescription] = useState(invoice?.description || '');

  useEffect(() => {
    if (invoice) {
      setClientName(invoice.clientName || '');
      setClientAddress(invoice.clientAddress || '');
      setStayDuration({
        start: invoice.stayDuration?.start ? new Date(invoice.stayDuration.start).toISOString().split('T')[0] : '',
        end: invoice.stayDuration?.end ? new Date(invoice.stayDuration.end).toISOString().split('T')[0] : '',
      });
      setInvoiceAmount(invoice.invoiceAmount || '');
      setStatus(invoice.status || 'Unpaid');
      setDescription(invoice.description || '');
    }
  }, [invoice]);

  const handleUpdateInvoice = async () => {
    if (!clientName.trim() || !invoiceAmount || isNaN(invoiceAmount)) {
      toast.error('Please enter a valid client name and invoice amount.');
      return;
    }

    if (
      (stayDuration.start && !isValidDate(stayDuration.start)) ||
      (stayDuration.end && !isValidDate(stayDuration.end))
    ) {
      toast.error('Please enter valid start and end dates.');
      return;
    }

    try {
      const invoiceRef = doc(db, 'invoices', invoice.id);
      const formattedStayDuration = {
        start: stayDuration.start ? new Date(stayDuration.start).toISOString() : null,
        end: stayDuration.end ? new Date(stayDuration.end).toISOString() : null,
      };

      await updateDoc(invoiceRef, {
        clientName: clientName.trim(),
        clientAddress: clientAddress.trim(),
        stayDuration: formattedStayDuration,
        invoiceAmount: parseFloat(invoiceAmount),
        status: status || 'Unpaid',
        description: description.trim() || 'Services Rendered',
        updatedAt: new Date().toISOString(),
      });

      // Update the selected invoice in the detailed view
      onInvoiceUpdated({
        ...invoice,
        clientName: clientName.trim(),
        clientAddress: clientAddress.trim(),
        stayDuration: formattedStayDuration,
        invoiceAmount: parseFloat(invoiceAmount),
        status: status || 'Unpaid',
        description: description.trim() || 'Services Rendered',
      });

      toast.success('Invoice updated successfully!');
    } catch (error) {
      console.error('Error updating invoice:', error);
      toast.error('Failed to update invoice.');
    }
  };

  return (
    <Modal size="xl" show={show} onHide={onClose} centered>
      <Modal.Header className="employee-log-modal-header" closeButton>
        <Modal.Title className="employee-log-modal-title">Edit Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="employee-log-modal-body">
        <Form className="employee-log-form">
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="editClientName">
                <Form.Label>Client's Name</Form.Label>
                <Form.Control
                  type="text"
                  value={clientName}
                  className='all-modal-form-control'
                  onChange={(e) => setClientName(e.target.value)}
                  placeholder="Enter client's name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="editClientAddress">
                <Form.Label>Client's Address</Form.Label>
                <Form.Control
                  type="text"
                  value={clientAddress}
                  className='all-modal-form-control'
                  onChange={(e) => setClientAddress(e.target.value)}
                  placeholder="Enter client's address"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="editStayStart">
                <Form.Label>Start Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.start}
                  onChange={(e) => setStayDuration({ ...stayDuration, start: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="editStayEnd">
                <Form.Label>End Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.end}
                  onChange={(e) => setStayDuration({ ...stayDuration, end: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editInvoiceAmount">
                <Form.Label>Invoice Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={invoiceAmount}
                  className='all-modal-form-control'
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                  placeholder="Enter invoice amount"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="editInvoiceNumber">
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control type="text" className='all-modal-form-control' value={invoice.invoiceNumber || 'N/A'} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="editStatus">
                <Form.Label>Status</Form.Label>
                <Form.Select value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="Unpaid">Unpaid</option>
                  <option value="Paid">Paid</option>
                  <option value="Overdue">Overdue</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="editDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className='all-modal-form-control'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description for services rendered"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="employee-log-modal-footer">
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={handleUpdateInvoice}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

// Delete Confirmation Modal Component
const DeleteConfirmationModal = ({ show, onClose, onConfirm, invoice }) => {
  return (
    <Modal size="xl" show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the invoice <strong>{invoice?.invoiceNumber || 'N/A'}</strong>?
      </Modal.Body>
      <Modal.Footer className="employee-log-modal-footer">
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="danger" onClick={onConfirm}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

// Detailed Invoice View Component
const DetailedInvoiceView = ({ invoice, onDismiss, onStatusChange, onEdit }) => {
  if (!invoice) return null;

  const handleStatusChangeInternal = async (newStatus) => {
    if (newStatus === invoice.status) return; // No change needed

    try {
      const invoiceRef = doc(db, 'invoices', invoice.id);
      await updateDoc(invoiceRef, {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });

      toast.success('Invoice status updated successfully!');
      onStatusChange(invoice.id, newStatus);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status.');
    }
  };

  return (
    <Card className="mb-4">
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <span className='detailed-head'>Invoice Details - {invoice.invoiceNumber}</span>
          <div>
            <Button variant="secondary"  className='internal-edit-buttons mr-2' onClick={onDismiss}>Dismiss</Button>
            <Button variant="warning"  className='internal-buttons ml-2' onClick={onEdit}> <FontAwesomeIcon icon={faPen} color="white" className='mr-2' />
            Edit Invoice</Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <Col md={6}><strong>Client Name:</strong> {invoice.clientName || 'N/A'}</Col>
          <Col md={6}><strong>Client Address:</strong> {invoice.clientAddress || 'N/A'}</Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}><strong>Invoice Number:</strong> {invoice.invoiceNumber || 'N/A'}</Col>
          <Col md={6}><strong>Invoice Amount:</strong> ${invoice.invoiceAmount?.toFixed(2) || '0.00'}</Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <strong>Status:</strong>
            <Form.Select
              value={invoice.status}
              onChange={(e) => handleStatusChangeInternal(e.target.value)}
              style={{ width: '150px', display: 'inline-block', marginLeft: '10px' }}
            >
              <option value="Unpaid">Unpaid</option>
              <option value="Paid">Paid</option>
              <option value="Overdue">Overdue</option>
            </Form.Select>
          </Col>
          <Col md={6}>
            <strong>Created At:</strong> {invoice.createdAt && isValidDate(invoice.createdAt) ? new Date(invoice.createdAt).toLocaleDateString() : 'N/A'}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <strong>Stay Duration:</strong>
            {invoice.stayDuration?.start && isValidDate(invoice.stayDuration.start)
              ? new Date(invoice.stayDuration.start).toLocaleDateString()
              : 'N/A'} - 
            {invoice.stayDuration?.end && isValidDate(invoice.stayDuration.end)
              ? new Date(invoice.stayDuration.end).toLocaleDateString()
              : 'N/A'}
          </Col>
          <Col md={6}>
            <strong>Description:</strong> {invoice.description || 'Services Rendered'}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

// Main Invoices Component with Real-Time Updates
const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [dateFilter, setDateFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true); // Start loading
    const q = query(collection(db, 'invoices'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoicesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sanitizedInvoices = invoicesData.map((invoice) => ({
        ...invoice,
        createdAt: isValidDate(invoice.createdAt) ? invoice.createdAt : null,
        stayDuration: {
          start: invoice.stayDuration?.start && isValidDate(invoice.stayDuration.start) ? invoice.stayDuration.start : null,
          end: invoice.stayDuration?.end && isValidDate(invoice.stayDuration.end) ? invoice.stayDuration.end : null,
        },
      }));

      setInvoices(sanitizedInvoices);
      setIsLoading(false); // End loading
    }, (error) => {
      console.error('Error fetching invoices:', error);
      toast.error('Failed to fetch invoices.');
      setIsLoading(false); // End loading even on error
    });

    return () => unsubscribe();
  }, []);

  const filterByDay = (invoices) => {
    const today = new Date();
    return invoices.filter((invoice) => {
      if (!invoice.createdAt) return false;
      const invoiceDate = new Date(invoice.createdAt);
      return (
        invoiceDate.getDate() === today.getDate() &&
        invoiceDate.getMonth() === today.getMonth() &&
        invoiceDate.getFullYear() === today.getFullYear()
      );
    });
  };

  const filterByWeek = (invoices) => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0);
    return invoices.filter((invoice) => {
      if (!invoice.createdAt) return false;
      const invoiceDate = new Date(invoice.createdAt);
      return invoiceDate >= startOfWeek && invoiceDate <= today;
    });
  };

  const filterByMonth = (invoices) => {
    const today = new Date();
    return invoices.filter((invoice) => {
      if (!invoice.createdAt) return false;
      const invoiceDate = new Date(invoice.createdAt);
      return (
        invoiceDate.getMonth() === today.getMonth() &&
        invoiceDate.getFullYear() === today.getFullYear()
      );
    });
  };

  const filterByYear = (invoices) => {
    const today = new Date();
    return invoices.filter((invoice) => {
      if (!invoice.createdAt) return false;
      const invoiceDate = new Date(invoice.createdAt);
      return invoiceDate.getFullYear() === today.getFullYear();
    });
  };

  const applyFilters = (invoices) => {
    let filtered = invoices;

    switch (dateFilter) {
      case 'day':
        filtered = filterByDay(filtered);
        break;
      case 'week':
        filtered = filterByWeek(filtered);
        break;
      case 'month':
        filtered = filterByMonth(filtered);
        break;
      case 'year':
        filtered = filterByYear(filtered);
        break;
      default:
        break;
    }

    if (statusFilter !== 'all') {
      filtered = filtered.filter(
        (invoice) =>
          invoice.status && invoice.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    if (searchTerm.trim() !== '') {
      filtered = filtered.filter(
        (invoice) =>
          (invoice.invoiceNumber &&
            invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (invoice.clientName &&
            invoice.clientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (invoice.createdAt &&
            new Date(invoice.createdAt)
              .toLocaleDateString()
              .includes(searchTerm))
      );
    }

    return filtered;
  };

  const filteredInvoices = applyFilters(invoices);

  const generatePDFForInvoice = (invoice) => {
    generatePDF(invoice); // Use the description from the invoice data
    toast.success('PDF generated successfully!');
  };

  const handleDeleteInvoice = async () => {
    if (!selectedInvoice) return;

    try {
      await deleteDoc(doc(db, 'invoices', selectedInvoice.id));
      toast.success('Invoice deleted successfully.');
      setShowDeleteModal(false);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Error deleting invoice:', error);
      toast.error('Failed to delete invoice.');
    }
  };

  const handleStatusChange = (invoiceId, newStatus) => {
    setInvoices((prevInvoices) =>
      prevInvoices.map((inv) =>
        inv.id === invoiceId ? { ...inv, status: newStatus } : inv
      )
    );

    if (selectedInvoice && selectedInvoice.id === invoiceId) {
      setSelectedInvoice((prevSelected) => ({
        ...prevSelected,
        status: newStatus,
      }));
    }
  };

  return (
    <Container fluid className="timecard-container">
      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Invoices</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="success"
            onClick={() => setShowCreateModal(true)}
            className="dashboard-add-client-btn" 
          >
            + Create Invoice
          </Button>
        </Col>
      </Row>
      <hr className="divider" />

      {/* Search and Filter Bar */}
      <Row className="mb-3">
        <Col md={8} className="mb-2 mb-md-0">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Search by client name, invoice number, or date"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col md={4} className="d-flex justify-content-end">
          <InputGroup className="me-2" style={{ width: '200px' }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </InputGroup.Text>
            <Form.Select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              <option value="all">All Dates</option>
              <option value="day">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </Form.Select>
          </InputGroup>

          <InputGroup className="me-2" style={{ width: '200px' }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faFilter} />
            </InputGroup.Text>
            <Form.Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">Status</option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Overdue">Overdue</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      {isLoading ? (
        <Row className="justify-content-center">
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <>
          {/* Detailed Invoice View */}
          {selectedInvoice && (
            <DetailedInvoiceView
              invoice={selectedInvoice}
              onDismiss={() => setSelectedInvoice(null)}
              onStatusChange={handleStatusChange}
              onEdit={() => setShowEditModal(true)}
            />
          )}

          {/* Invoice List Table */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Client Name</th>
                <th>Address</th>
                <th>Invoice Amount</th>
                <th>Status</th>
                <th style={{ width: '280px' }}>Actions</th> 
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice) => (
                  <tr
                    key={invoice.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedInvoice(invoice)}
                  >
                    <td>{invoice.invoiceNumber || 'N/A'}</td>
                    <td>{invoice.clientName || 'N/A'}</td>
                    <td>{invoice.clientAddress || 'N/A'}</td>
                    <td>${invoice.invoiceAmount?.toFixed(2) || 'N/A'}</td>
                    <td>{invoice.status || 'Unpaid'}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="md"
                        className='me-2 internal-buttons'
                        onClick={(e) => {
                          e.stopPropagation();
                          generatePDFForInvoice(invoice); // Directly generate PDF using invoice data
                        }}
                      >
                        Download PDF
                      </Button>
                      <Button
                        variant="danger"
                        size="md"
                        className='me-2 internal-delete-buttons'
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedInvoice(invoice);
                          setShowDeleteModal(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} color="white" className='mr-2' />
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No invoices found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}

      {/* Create Invoice Modal */}
      <CreateInvoiceModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onInvoiceCreated={() => {
          setShowCreateModal(false);
        }}
      />

      {/* Edit Invoice Modal */}
      {selectedInvoice && (
        <EditInvoiceModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          invoice={selectedInvoice}
          onInvoiceUpdated={(updatedInvoice) => {
            setShowEditModal(false);
            // Update the selected invoice in the detailed view
            setSelectedInvoice(updatedInvoice);
          }}
        />
      )}

      {/* Delete Confirmation Modal */}
      {selectedInvoice && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteInvoice}
          invoice={selectedInvoice}
        />
      )}

      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Invoices;
