import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicRouter from './components/PublicRouter'; // Public routes for users
import AdminDashboard from './components/AdminDashboard'; // Admin dashboard that now handles admin routes
import LoginForm from './components/LoginForm'; // Login form
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './App.css';

// Add FontAwesome icons to the library
library.add(fas, fab);

// Scroll to the top when navigating between pages
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Helmet>
        <title>Imagine Seniorcare Inc</title>
        <meta name="description" content="Imagine Seniorcare Inc provides compassionate senior care services." />
      </Helmet>
      <Routes>
        {/* Public Routes */}
        <Route path="/*" element={<PublicRouter />} />

        {/* Admin Routes */}
        <Route path="/admin/*" element={<AdminDashboard />} />

        {/* Login Route */}
        <Route path="/login" element={<LoginForm />} />
      </Routes>
    </Router>
  );
};

export default App;
