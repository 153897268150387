import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles/CreateInvoice.css';
import { toast } from 'react-toastify';

const CreateInvoice = ({ show, onClose }) => {
  const [clientName, setClientName] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [stayDuration, setStayDuration] = useState({ start: '', end: '' });
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (show) {
      generateInvoiceNumber();
      resetFields();
    }
  }, [show]);

  const resetFields = () => {
    setClientName('');
    setClientAddress('');
    setStayDuration({ start: '', end: '' });
    setInvoiceAmount('');
    setDescription('');
  };

  // Helper function to generate the invoice number
  const generateInvoiceNumber = () => {
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`;
    const randomFourDigit = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
    const invoiceNum = `${formattedDate}-${randomFourDigit}`;
    setInvoiceNumber(invoiceNum);
  };
  

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const handleSaveInvoice = async () => {
    if (!clientName || !invoiceAmount || isNaN(invoiceAmount)) {
      toast.error('Please enter a valid client name and invoice amount.');
      return;
    }

    if (
      (stayDuration.start && !isValidDate(stayDuration.start)) ||
      (stayDuration.end && !isValidDate(stayDuration.end))
    ) {
      toast.error('Please enter valid start and end dates.');
      return;
    }

    try {
      const invoiceData = {
        clientName: clientName.trim(),
        clientAddress: clientAddress.trim(),
        stayDuration: {
          start: stayDuration.start ? new Date(stayDuration.start).toISOString() : null,
          end: stayDuration.end ? new Date(stayDuration.end).toISOString() : null,
        },
        invoiceAmount: parseFloat(invoiceAmount),
        invoiceNumber,
        createdAt: new Date().toISOString(),
        description: description.trim() || 'Services Rendered',
        status: 'Unpaid',
      };

      await addDoc(collection(db, 'invoices'), invoiceData);
      toast.success('Invoice created and saved successfully in Invoices!');
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error saving invoice:', error);
      toast.error('Failed to save invoice.');
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      centered
      className="create-invoice-modal"
    >
      <Modal.Header className="employee-log-modal-header" closeButton>
        <Modal.Title className="employee-log-modal-title">Create New Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="employee-log-modal-body">
        <Form className="employee-log-form">
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="clientName">
                <Form.Label>Client's Name</Form.Label>
                <Form.Control
                  type="text"
                  className="all-modal-form-control"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  placeholder="Enter client's name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="clientAddress">
                <Form.Label>Client's Address</Form.Label>
                <Form.Control
                  type="text"
                  className="all-modal-form-control"
                  value={clientAddress}
                  onChange={(e) => setClientAddress(e.target.value)}
                  placeholder="Enter client's address"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="stayStart">
                <Form.Label>Start Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.start}
                  onChange={(e) =>
                    setStayDuration({ ...stayDuration, start: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="stayEnd">
                <Form.Label>End Date of Stay</Form.Label>
                <Form.Control
                  type="date"
                  value={stayDuration.end}
                  onChange={(e) =>
                    setStayDuration({ ...stayDuration, end: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="invoiceAmount">
                <Form.Label>Invoice Amount</Form.Label>
                <Form.Control
                  type="number"
                  className="all-modal-form-control"
                  value={invoiceAmount}
                  onChange={(e) => setInvoiceAmount(e.target.value)}
                  placeholder="Enter invoice amount"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="invoiceNumber">
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control
                  className="all-modal-form-control"
                  type="text"
                  value={invoiceNumber}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="all-modal-form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description for services rendered"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="employee-log-modal-footer">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveInvoice}>
          Create Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateInvoice;