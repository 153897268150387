import React, { useState, useEffect } from 'react';
import { Card, Spinner, Row, Col, Modal, Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/review.css';

function LeaveReview() {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleStarClick = (stars) => {
    setRating(stars);
    if (stars === 5) {
      setIsLoading(true);
      window.location.href = 'https://g.page/r/Cbi81v4qB7wqEAE/review';
    } else if (stars < 5 && window.innerWidth < 768) {
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (rating < 5 && window.innerWidth >= 768) {
      setIsModalVisible(false);
    }
  }, [rating]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    const formData = new FormData(e.target);
    const formJSON = Object.fromEntries(formData.entries());

    try {
      const response = await fetch(e.target.action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formJSON),
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        e.target.reset();
        setIsModalVisible(false);
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <>
      <Container fluid className="contact-header text-center">
        <h1 className="display-4">Leave us a review</h1>
        <p className="contact-jumbo">Let us care for your loved one</p>
      </Container>
      <div className="background-image-container">
        <div className="App">
          <Card className="feedback-card">
            <Card.Body>
              {isLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" variant="light" />
                </div>
              ) : isFormSubmitted ? (
                <div className="thank-you-message">
                  <h2>Thank you for your feedback!</h2>
                  <p>We appreciate your input and will use it to improve our services.</p>
                </div>
              ) : (
                <>
                  <h1 className='leave-head'>We Value Your Feedback!</h1>
                  {rating === 0 && (
                    <div className="stars">
                      {[...Array(5)].map((_, index) => (
                        <span
                          key={index}
                          className={`star ${index < (hoverRating || rating) ? 'filled' : ''}`}
                          onClick={() => handleStarClick(index + 1)}
                          onMouseEnter={() => setHoverRating(index + 1)}
                          onMouseLeave={() => setHoverRating(0)}
                        >★</span>
                      ))}
                    </div>
                  )}
                  {rating > 0 && rating < 5 && (
                    <motion.div
                      className="feedback-container"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      style={{ display: isFormSubmitted ? 'none' : 'block' }}
                    >
                      <form
                        id="feedback-form"
                        action="https://formspree.io/f/xdknbzok"
                        method="POST"
                        className="feedback-form"
                        onSubmit={handleFormSubmit}
                      >
                        <h2 className='leave-head'>We're sorry to hear that!</h2>
                        <Row>
                          <Col md={6}>
                            <input
                              type="text"
                              name="name"
                              placeholder="Full Name"
                              required
                              className="form-control"
                            />
                          </Col>
                          <Col md={6}>
                            <input
                              type="text"
                              name="reason"
                              placeholder="Reason"
                              required
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <textarea
                          name="feedback"
                          placeholder="Please let us know how we can improve..."
                          required
                          className="form-control mt-3"
                        ></textarea>
                        <button type="submit" className="btn btn-primary mt-3">Submit</button>
                      </form>
                    </motion.div>
                  )}
                </>
              )}
            </Card.Body>
          </Card>

          {/* Modal for smaller screens */}
          <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)} fullscreen>
            <Modal.Body className="feedback-modal">
              <h2>We're sorry to hear that!</h2>
              <form
                id="modal-feedback-form"
                action="https://formspree.io/f/xdknbzok"
                method="POST"
                className="feedback-form"
                onSubmit={handleFormSubmit}
              >
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  required
                  className="form-control mb-3"
                />
                <input
                  type="text"
                  name="reason"
                  placeholder="Reason"
                  required
                  className="form-control mb-3"
                />
                <textarea
                  name="feedback"
                  placeholder="Please let us know how we can improve..."
                  required
                  className="form-control mb-3"
                  style={{ height: '150px' }}
                ></textarea>
                <button type="submit" className="btn btn-primary w-100">Submit</button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default LeaveReview;
