import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; 
import '../styles/Home.css';
import backgroundImage from '../images/logo.webp';
import InfoSection from './InfoSection';
import ServicesSection from './ServicesSection';
import ContactForm from './ContactForm';

const Home = () => (
  <div className="home">
    <Helmet>
      <title>Imagine Seniorcare Inc - Home</title>
      <meta name="description" content="Welcome to Imagine Seniorcare Inc. We provide compassionate, personalized care for your loved ones." />
      <meta name="keywords" content="senior care, in-home caregivers, senior services, senior transportation" />
    </Helmet>
    <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay"></div>
      <div className="hero-content-home text-center">
        <div className="hero-buttons">
          <Link to="/services">
            <button className="btn hero-button btn-lg m-2" style={{ fontWeight: 'bold' }}>Our Services</button>
          </Link>
          <Link to="/contact">
            <button className="btn hero-button btn-lg m-2" style={{ fontWeight: 'bold' }}>Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
    <div className="main-content">
      <InfoSection />
      <ServicesSection />
      <ContactForm />
    </div>
  </div>
);

export default Home;