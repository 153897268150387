import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap'; 
import '../styles/About.css';
import AboutSection from './AboutSection';
import OwnersBar from './OwnersBar';
import Owners from './Owners';

const About = () => (
  <div className="contact-container">
    <Helmet>
      <title>About Us - Imagine Seniorcare Inc</title>
      <meta name="description" content="Learn about Imagine Seniorcare Inc, our story, values, and commitment to providing compassionate senior care." />
      <meta name="keywords" content="about Imagine Seniorcare, senior care company, compassionate care, senior care values" />
    </Helmet>
    <Container fluid className="contact-header text-center">
      <h1 className="display-4">About Us</h1>
      <p className="contact-jumbo">Let us care for your loved one</p>
    </Container>
    <div className="main-content">
      <AboutSection />
      <OwnersBar />
      <Owners />
    </div>
  </div>
);

export default About;
