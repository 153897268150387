import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  Dropdown,
  Modal,
  Form,
  Table,
  InputGroup,
  FormControl,
  Breadcrumb,
} from "react-bootstrap";

import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase"; // Adjust this path if needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFilePdf,
  faImage,
  faEllipsisV,
  faFolder,
  faThLarge,
  faList,
  faSearch,
  faArrowLeft,
  faFilter,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify"; // Updated import
import ClientUploadFile from "../components/contracts/ClientUploadFile"; // Adjusted import path
import "react-toastify/dist/ReactToastify.css";

const ClientContracts = () => {
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [clients, setClients] = useState([]); // State for client data
  const [allFiles, setAllFiles] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({
    id: "main",
    folderName: "Client Contracts Drive",
  });
  const [breadcrumb, setBreadcrumb] = useState([
    { id: "main", folderName: "Client Contracts Drive" },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showRenameFolderModal, setShowRenameFolderModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeColorModal, setShowChangeColorModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [moveToFolderId, setMoveToFolderId] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [folderColor, setFolderColor] = useState("#A9A9A9");
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState("row");
  const [sortOption, setSortOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch clients data
  const fetchClients = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "clients"));
      const clientsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsData);
    } catch (error) {
      console.error("Error fetching clients data:", error);
      toast.error("Failed to fetch clients.");
    }
  }, []);

  // Fetch contracts and clients
  const fetchAllData = useCallback(async () => {
    setLoading(true);
    await fetchClients(); // Fetch clients data first
    try {
      const querySnapshot = await getDocs(collection(db, "contracts"));
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
          uploadDate: docData.uploadDate
            ? typeof docData.uploadDate.toDate === "function"
              ? docData.uploadDate.toDate()
              : new Date(docData.uploadDate)
            : new Date(),
        };
      });

      const allFilesData = data.filter((item) => !item.isFolder);
      setAllFiles(allFilesData);

      const filteredFiles = data.filter(
        (item) =>
          !item.isFolder &&
          item.folderId === (currentFolder.id === "main" ? "main" : currentFolder.id)
      );

      const allFolders = data.filter((item) => item.isFolder);

      setFiles(filteredFiles);
      setFolders(allFolders);
    } catch (error) {
      console.error("Error fetching contracts:", error);
      toast.error("Failed to fetch contracts.");
    } finally {
      setLoading(false);
    }
  }, [currentFolder, fetchClients]);

  useEffect(() => {
    fetchAllData();
  }, [currentFolder, fetchAllData]);

  const getFileIcon = (file) => {
    if (!file || !file.mimeType) return faFileAlt;
    const mimeType = file.mimeType.toLowerCase();
    if (mimeType.includes("pdf")) return faFilePdf;
    if (mimeType.includes("image")) return faImage;
    return faFileAlt;
  };

  const getFileTypeText = (file) => {
    if (!file || !file.mimeType) return "Unknown";
    const mimeType = file.mimeType.toLowerCase();
    if (mimeType.includes("pdf")) return "PDF";
    if (mimeType.includes("image")) return "Image";
    return "Other";
  };

  const getFilteredSortedFiles = () => {
    let filteredFiles = allFiles.filter((file) =>
      file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (sortOption === "name") {
      filteredFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));
    } else if (sortOption === "uploadDate") {
      filteredFiles.sort((a, b) => b.uploadDate - a.uploadDate);
    }
    return filteredFiles;
  };

  const renderRowView = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Contract Name</th>
          <th>Type</th>
          <th>Upload Date</th>
          <th style={{ width: "320px" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {getFilteredSortedFiles().length > 0 ? (
          getFilteredSortedFiles().map((file) => (
            <tr key={file.id} style={{ cursor: "pointer" }}>
              <td onClick={() => openRenameModal(file)}>
                {getClientNameById(file.clientId)}
              </td>
              <td onClick={() => openRenameModal(file)}>{file.fileName}</td>
              <td onClick={() => openRenameModal(file)}>
                {getFileTypeText(file)}
              </td>
              <td onClick={() => openRenameModal(file)}>
              {file.uploadDate ? file.uploadDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }) : "N/A"}
              </td>
              <td>
                <Button
                  variant="primary"
                  size="md"
                  className="me-2 internal-buttons"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(file.fileUrl, "_blank");
                  }}
                >
                  Download Contract
                </Button>
                <Button
                  variant="danger"
                  size="md"
                  className="me-2 internal-delete-buttons"
                  onClick={(e) => {
                    e.stopPropagation();
                    openDeleteModal(file);
                  }}
                  title="Delete File"
                >
                  <FontAwesomeIcon icon={faTrash} color="white" className="mr-2" />
                  Delete
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center no-files-message">
              No files found.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  const getClientNameById = (clientId) => {
    const client = clients.find((c) => c.id === clientId);
    return client ? client.name : "Unknown";
  };

  const renderFoldersView = () => (
    <Row>
      {folders.length === 0 ? (
        <div className="text-center w-100">
          <FontAwesomeIcon icon={faFolder} size="4x" className="mb-3" />
          <p>No folders available</p>
        </div>
      ) : (
        folders.map((folder) => (
          <Col key={folder.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card
              className="folder-card"
              style={{ position: "relative", overflow: "visible" }}
              onClick={() => navigateToFolder(folder)}
            >
              <div className="d-flex align-items-center folder-row justify-content-between">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faFolder}
                    size="lg"
                    className="folder-icon"
                    style={{ color: folder.color || "#A9A9A9" }}
                  />
                  <Card.Title className="folder-title">{folder.folderName}</Card.Title>
                </div>
                <div>
                  <Dropdown
                    align="end"
                    drop="down"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle
                      variant="light"
                      className="fa-ellipsis-dropdown"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        className="folder-ellipse-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        position: "absolute",
                        zIndex: 1050,
                        top: "100%",
                        right: "0",
                      }}
                    >
                      <Dropdown.Item onClick={() => openChangeColorModal(folder)}>
                        Change Folder Color
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => openRenameFolderModal(folder)}>
                        Rename Folder
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => openDeleteModal(folder)}>
                        Delete Folder
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );

  const renderCardView = () => (
    <Row>
      {files.length === 0 ? (
        <div className="text-center w-100">
          <FontAwesomeIcon icon={faFileAlt} size="4x" className="mb-3" />
          <p>No files available</p>
        </div>
      ) : (
        files.map((file) => (
          <Col
            key={file.id}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className="mb-4 dropdown-space"
          >
            <Card className="file-card d-flex flex-column justify-content-between">
              <Card.Body className="text-center">
                <FontAwesomeIcon
                  icon={getFileIcon(file)}
                  size="3x"
                  className="file-icon mb-4"
                />
                <Card.Footer
                  className="d-flex justify-content-around"
                  style={{ backgroundColor: "transparent" }}
                >
                  {/* You can add footer content here if needed */}
                </Card.Footer>
                <div className="file-header d-flex align-items-start justify-content-between">
                  <Card.Title className="file-title">{file.fileName}</Card.Title>
                  <Dropdown align="end" drop="down" renderMenuOnMount={true}>
                    <Dropdown.Toggle variant="light" className="fa-ellipsis-dropdown">
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        className="folder-ellipse-icon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => openMoveModal(file)}>
                        Move File
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => window.open(file.fileUrl, "_blank")}
                      >
                        Download File
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => openRenameModal(file)}>
                        Rename File
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => openDeleteModal(file)}>
                        Delete File
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );

  const navigateToFolder = (folder) => {
    setCurrentFolder({ id: folder.id, folderName: folder.folderName });
    setBreadcrumb((prev) => [
      ...prev,
      { id: folder.id, folderName: folder.folderName },
    ]);
  };

  const handleMoveToFolder = async () => {
    if (selectedFile && moveToFolderId !== undefined) {
      try {
        const fileRef = doc(db, "contracts", selectedFile.id);
        await updateDoc(fileRef, { folderId: moveToFolderId || "main" });
        setShowMoveModal(false);
        setSelectedFile(null);
        setMoveToFolderId(null);
        fetchAllData();
        toast.success("File moved successfully.");
      } catch (error) {
        console.error("Error moving file: ", error);
        toast.error("Failed to move file.");
      }
    }
  };

  const handleRenameFile = async () => {
    if (selectedFile && newFileName) {
      try {
        const fileRef = doc(db, "contracts", selectedFile.id);
        await updateDoc(fileRef, { fileName: newFileName });
        setShowRenameModal(false);
        setSelectedFile(null);
        setNewFileName("");
        fetchAllData();
        toast.success("File renamed successfully.");
      } catch (error) {
        console.error("Error renaming file: ", error);
        toast.error("Failed to rename file.");
      }
    }
  };

  const handleDelete = async () => {
    if (selectedFile) {
      try {
        const fileRef = doc(db, "contracts", selectedFile.id);
        await deleteDoc(fileRef);
        setShowDeleteModal(false);
        setSelectedFile(null);
        fetchAllData();
        toast.success("File deleted successfully.");
      } catch (error) {
        console.error("Error deleting file:", error);
        toast.error("Failed to delete file.");
      }
    } else if (selectedFolder) {
      try {
        const filesInFolder = allFiles.filter(
          (file) => file.folderId === selectedFolder.id
        );
        const batch = writeBatch(db);
        filesInFolder.forEach((file) => {
          const fileRef = doc(db, "contracts", file.id);
          batch.update(fileRef, { folderId: "main" });
        });
        await batch.commit();
        const folderRef = doc(db, "contracts", selectedFolder.id);
        await deleteDoc(folderRef);
        setShowDeleteModal(false);
        setSelectedFolder(null);
        if (currentFolder.id === selectedFolder.id) {
          handleBackToMain();
        }
        fetchAllData();
        toast.success(
          "Folder deleted successfully. All contained files moved to main screen."
        );
      } catch (error) {
        console.error("Error deleting folder:", error);
        toast.error("Failed to delete folder.");
      }
    }
  };

  const handleRenameFolder = async () => {
    if (selectedFolder && newFolderName) {
      try {
        const folderRef = doc(db, "contracts", selectedFolder.id);
        await updateDoc(folderRef, { folderName: newFolderName });
        setShowRenameFolderModal(false);
        setSelectedFolder(null);
        setNewFolderName("");
        fetchAllData();
        toast.success("Folder renamed successfully.");
      } catch (error) {
        console.error("Error renaming folder:", error);
        toast.error("Failed to rename folder.");
      }
    }
  };

  const handleColorChange = async () => {
    if (selectedFolder && folderColor) {
      try {
        const folderRef = doc(db, "contracts", selectedFolder.id);
        await updateDoc(folderRef, { color: folderColor });
        setShowChangeColorModal(false);
        setSelectedFolder(null);
        fetchAllData();
        toast.success("Folder color changed successfully.");
      } catch (error) {
        console.error("Error changing folder color:", error);
        toast.error("Failed to change folder color.");
      }
    }
  };

  const handleCreateFolder = async () => {
    if (newFolderName) {
      try {
        const folderData = {
          folderName: newFolderName,
          isFolder: true,
          folderId: currentFolder.id === "main" ? "main" : currentFolder.id,
          uploadDate: serverTimestamp(),
        };
        await addDoc(collection(db, "contracts"), folderData);
        closeCreateFolderModal(); // Reset state on close
        fetchAllData();
        toast.success("Folder created successfully.");
      } catch (error) {
        console.error("Error creating folder:", error);
        toast.error("Failed to create folder.");
      }
    }
  };

  const openCreateFolderModal = () => {
    setNewFolderName(""); // Clear any existing input
    setShowCreateFolderModal(true);
  };

  const closeCreateFolderModal = () => {
    setShowCreateFolderModal(false);
    setNewFolderName(""); // Clear input on close
  };

  const openMoveModal = (file) => {
    setSelectedFile(file);
    setShowMoveModal(true);
  };

  const openRenameModal = (file) => {
    setSelectedFile(file);
    setNewFileName(file.fileName);
    setShowRenameModal(true);
  };

  const openDeleteModal = (item) => {
    if (item.isFolder) {
      setSelectedFolder(item);
      setSelectedFile(null);
    } else {
      setSelectedFile(item);
      setSelectedFolder(null);
    }
    setShowDeleteModal(true);
  };

  const openRenameFolderModal = (folder) => {
    setSelectedFolder(folder);
    setNewFolderName(folder.folderName);
    setShowRenameFolderModal(true);
  };

  const openChangeColorModal = (folder) => {
    setSelectedFolder(folder);
    setFolderColor(folder.color || "#A9A9A9");
    setShowChangeColorModal(true);
  };

  const handleBackToMain = () => {
    setCurrentFolder({ id: "main", folderName: "Client Contracts Drive" });
    setBreadcrumb([{ id: "main", folderName: "Client Contracts Drive" }]);
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = breadcrumb.slice(0, index + 1);
    const newFolder = newPath[newPath.length - 1];
    setCurrentFolder({ id: newFolder.id, folderName: newFolder.folderName });
    setBreadcrumb(newPath);
  };

  const handleViewSwitch = (view) => {
    setViewType(view);
  };

  return (
    <Container fluid className="timecard-container">
      {/* Toast Container */}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />

      {currentFolder.id !== "main" && (
        <Breadcrumb className="breadcrumb-title">
          {breadcrumb.map((crumb, index) => (
            <Breadcrumb.Item
              key={crumb.id}
              onClick={() => handleBreadcrumbClick(index)}
              active={index === breadcrumb.length - 1}
              className={
                index === 0
                  ? "breadcrumb-main"
                  : index === breadcrumb.length - 1
                  ? "active-breadcrumb"
                  : ""
              }
              linkAs="span"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              {crumb.folderName}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}

      <Row className="align-items-center mb-3">
        <Col>
          {currentFolder.id === "main" && (
            <h1 className="timecard-title">{currentFolder.folderName}</h1>
          )}
        </Col>
        <Col className="text-end">
          <Button
            className="filter-button dashboard-add-client-btn custom-width-button mr-2"
            onClick={openCreateFolderModal}
          >
            + Create Folder
          </Button>
          <ClientUploadFile
            fetchData={fetchAllData}
            folders={folders}
            clients={clients}
          />
        </Col>
      </Row>

      <hr className="divider" />

      <Row className="mb-4 align-items-center">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Search by contract name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col className="text-end d-flex justify-content-end align-items-center">
          <Button
            variant="light"
            className={`view-icon-button custom-width-button me-2 ${
              viewType === "row" ? "active square-button" : ""
            }`}
            onClick={() => handleViewSwitch("row")}
            title="Row View"
          >
            <FontAwesomeIcon icon={faList} className="mr-2" />
            Row View
          </Button>

          <Button
            variant="light"
            className={`view-icon-button custom-width-button me-2 ${
              viewType === "card" ? "active square-button" : ""
            }`}
            onClick={() => handleViewSwitch("card")}
            title="Card View"
          >
            <FontAwesomeIcon icon={faThLarge} className="mr-2" />
            Card View
          </Button>

          <InputGroup style={{ width: "200px" }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faFilter} />
            </InputGroup.Text>
            <Form.Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="">Default Filter</option>
              <option value="name">File Name (A-Z)</option>
              <option value="uploadDate">Upload Date (Newest First)</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      {currentFolder.id !== "main" && (
        <>
          <Row className="mb-4">
            <Col className="text-end">
              <Button
                className="filter-button dashboard-add-client-btn custom-width-button mr-2"
                onClick={handleBackToMain}
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                Back to Main
              </Button>
            </Col>
          </Row>
          <hr className="divider" />
        </>
      )}

      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <>
          {viewType === "card" && currentFolder.id === "main" && (
            <div className="folders-container">
              <h2 className="applications-title">Folders</h2>
              {renderFoldersView()}
              <hr className="divider" />
              <h2 className="applications-title">Contract Files</h2>
            </div>
          )}
          {viewType === "row" ? renderRowView() : renderCardView()}
        </>
      )}

      {/* Modals for file and folder actions */}
      {/* Change Folder Color Modal */}
      <Modal
        show={showChangeColorModal}
        onHide={() => setShowChangeColorModal(false)}
      >
        <Modal.Header closeButton className="employee-log-modal-title">
          <Modal.Title className="employee-log-modal-title">
            Change Folder Color
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form.Group>
            <Form.Label>Select Folder Color</Form.Label>
            <div className="color-options d-flex justify-content-around">
              {[
                "#FF0000",
                "#FF7F00",
                "#FFFF00",
                "#00FF00",
                "#0000FF",
                "#4B0082",
                "#8B00FF",
                "#A9A9A9",
              ].map((color) => (
                <div
                  key={color}
                  className="color-circle"
                  style={{
                    backgroundColor: color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: color === folderColor ? "2px solid black" : "none",
                  }}
                  onClick={() => setFolderColor(color)}
                />
              ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowChangeColorModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleColorChange}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Rename File Modal */}
      <Modal show={showRenameModal} onHide={() => setShowRenameModal(false)}>
        <Modal.Header closeButton className="employee-log-modal-title">
          <Modal.Title className="employee-log-modal-title">
            Rename File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form.Group>
            <Form.Label>New Contract Name</Form.Label>
            <Form.Control
              type="text"
              className="all-modal-form-control"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
              placeholder="Enter new contract name"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRenameModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRenameFile}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Rename Folder Modal */}
      <Modal
        show={showRenameFolderModal}
        onHide={() => setShowRenameFolderModal(false)}
      >
        <Modal.Header closeButton className="employee-log-modal-title">
          <Modal.Title className="employee-log-modal-title">
            Rename Folder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form.Group>
            <Form.Label>New Folder Name</Form.Label>
            <Form.Control
              type="text"
              className="all-modal-form-control"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRenameFolderModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRenameFolder}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="employee-log-modal-title">
          <Modal.Title className="employee-log-modal-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <p>
            Are you sure you want to delete this{" "}
            {selectedFolder ? "folder" : "file"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Move File Modal */}
      <Modal show={showMoveModal} onHide={() => setShowMoveModal(false)}>
        <Modal.Header closeButton className="employee-log-modal-title">
          <Modal.Title className="employee-log-modal-title">
            Move File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <p>Select a folder to move the file to:</p>
          <Form.Select
            onChange={(e) => setMoveToFolderId(e.target.value)}
            defaultValue=""
          >
            <option value="" disabled>
              Select folder
            </option>
            <option value="main">Main Screen</option>
            {folders.map((folder) => (
              <option key={folder.id} value={folder.id}>
                {folder.folderName}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button
            variant="secondary"
            onClick={() => setShowMoveModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleMoveToFolder}>
            Move
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Create Folder Modal */}
      <Modal show={showCreateFolderModal} onHide={closeCreateFolderModal}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">
            Create New Folder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form.Group>
            <Form.Label>Folder Name</Form.Label>
            <Form.Control
              type="text"
              className="all-modal-form-control"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={closeCreateFolderModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateFolder}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ClientContracts;
