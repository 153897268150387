import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import '../styles/ConPage.css';

const ContactPage = () => {
  const [state, handleSubmit] = useForm("xdknzroj");

  return (
    <div className="contact-page-container">
      {state.succeeded ? (
        <div className="contact-page success-message">
          <h2>Successfully Submitted</h2>
          <p className='contact-formspree'>Thank you for reaching out to us. <br>
          </br>We will get back to you shortly.</p>
        </div>
      ) : (
        <Form onSubmit={handleSubmit} className="contact-page">
  <Row>
    <Col xs={12} md={6} className="mb-3">
      <Form.Group>
        <Form.Label htmlFor="firstName">Your First name</Form.Label>
        <Form.Control 
          type="text" 
          id="firstName" 
          name="firstName" 
          className="contact-page-first-name" 
          placeholder="Jane" 
          required 
        />
        <ValidationError prefix="First Name" field="firstName" errors={state.errors} />
      </Form.Group>
    </Col>
    <Col xs={12} md={6} className="mb-3">
      <Form.Group>
        <Form.Label htmlFor="lastName">Your Last name</Form.Label>
        <Form.Control 
          type="text" 
          id="lastName" 
          name="lastName" 
          className="contact-page-last-name" 
          placeholder="Doe" 
          required 
        />
        <ValidationError prefix="Last Name" field="lastName" errors={state.errors} />
      </Form.Group>
    </Col>
  </Row>
  <Row>
    <Col xs={12} md={6} className="mb-3">
      <Form.Group>
        <Form.Label htmlFor="email">Your Email address</Form.Label>
        <Form.Control 
          type="email" 
          id="email" 
          name="email" 
          className="contact-page-email" 
          placeholder="janedoe@email.com" 
          required 
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </Form.Group>
    </Col>
    <Col xs={12} md={6} className="mb-3">
      <Form.Group>
        <Form.Label htmlFor="subject">Subject</Form.Label>
        <Form.Control 
          type="text" 
          id="subject" 
          name="subject" 
          className="contact-page-subject" 
          placeholder="Enter subject" 
          required 
        />
        <ValidationError prefix="Subject" field="subject" errors={state.errors} />
      </Form.Group>
    </Col>
  </Row>
  <Form.Group className="mb-3">
    <Form.Label htmlFor="message">Your message</Form.Label>
    <Form.Control 
      as="textarea" 
      id="message" 
      name="message" 
      className="contact-page-message" 
      rows={5} 
      placeholder="Enter your question or message" 
      required 
    />
    <ValidationError prefix="Message" field="message" errors={state.errors} />
  </Form.Group>
  <Row className="justify-content-center">
    <Col xs="auto">
      <Button variant="primary" type="submit" className="contact-page-submit" disabled={state.submitting}>
        Send Message
      </Button>
    </Col>
  </Row>
</Form>
      )}
    </div>
  );
}

export default ContactPage;
