// EmployeeTimecard.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Spinner,
} from 'react-bootstrap';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { FaTrash } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addDays } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/EmployeeTimecard.css';
import '../styles/Dashboard.css'; // Ensure Dashboard styles are available

const EmployeeTimecard = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEmployeeDetailsModal, setShowEmployeeDetailsModal] = useState(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Fields for managing employee's timesheet entries
  const [breakTime, setBreakTime] = useState('');
  const [approved, setApproved] = useState(false);
  const [overtime, setOvertime] = useState('');

  // State for search and sort
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');

  // State for new employee fields
  const [newEmployeeName, setNewEmployeeName] = useState('');
  const [newEmployeeEmail, setNewEmployeeEmail] = useState('');
  const [newEmployeePosition, setNewEmployeePosition] = useState('');

  // State for editing employee details
  const [editEmployeeName, setEditEmployeeName] = useState('');
  const [editEmployeeEmail, setEditEmployeeEmail] = useState('');
  const [editEmployeePosition, setEditEmployeePosition] = useState('');

  // Time selection states
  const [clockInHour, setClockInHour] = useState('');
  const [clockInMinute, setClockInMinute] = useState('');
  const [clockInPeriod, setClockInPeriod] = useState('');
  const [clockOutHour, setClockOutHour] = useState('');
  const [clockOutMinute, setClockOutMinute] = useState('');
  const [clockOutPeriod, setClockOutPeriod] = useState('');

  // Date and day of the week selection states
  const [isSpecificDay, setIsSpecificDay] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Toggle for manual hour logging
  const [manualHours, setManualHours] = useState(false);

  // Checkbox states for repeating fields
  const [repeatBreakTime, setRepeatBreakTime] = useState(false);
  const [repeatOvertime, setRepeatOvertime] = useState(false);

  // State for manual hours
  const [workedHours, setWorkedHours] = useState('');

  // **Loading State**
  const [isLoading, setIsLoading] = useState(false);

  // Fetch employees from Firestore when the component mounts
  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true); // Start loading
      try {
        const querySnapshot = await getDocs(collection(db, 'employees'));
        const employeesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesData); // Updates the local state
      } catch (error) {
        console.error('Error fetching employees:', error);
        toast.error('Failed to fetch employees. Please try again.');
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchEmployees();
    // **Empty dependency array ensures this runs only once**
  }, []);

  // Calculate the number of days between startDate and endDate
  const calculateDaysBetween = (startDate, endDate) => {
    const days = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      days.push(new Date(currentDate));
      currentDate = addDays(currentDate, 1);
    }

    return days;
  };

  // Handle adding a new employee
  const handleAddEmployee = async () => {
    if (!newEmployeeName || !newEmployeeEmail || !newEmployeePosition) {
      toast.error('Please fill out all fields.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'employees'), {
        name: newEmployeeName,
        email: newEmployeeEmail,
        position: newEmployeePosition,
      });

      const newEmployee = {
        id: docRef.id,
        name: newEmployeeName,
        email: newEmployeeEmail,
        position: newEmployeePosition,
      };

      setEmployees((prevEmployees) => [...prevEmployees, newEmployee]); // Update local state
      resetAddEmployeeFields();
      setShowAddEmployeeModal(false);
      toast.success('Employee added successfully!');
    } catch (error) {
      console.error('Error adding employee:', error);
      toast.error('Failed to add employee. Please try again.');
    }
  };

  // Reset fields for adding a new employee
  const resetAddEmployeeFields = () => {
    setNewEmployeeName('');
    setNewEmployeeEmail('');
    setNewEmployeePosition('');
  };

  // Handle editing an existing employee
  const handleEditEmployee = (employee) => {
    setSelectedEmployee(employee);
    setEditEmployeeName(employee.name);
    setEditEmployeeEmail(employee.email);
    setEditEmployeePosition(employee.position);
    setShowEditEmployeeModal(true);
  };

  // Save changes after editing an employee
  const handleSaveEditEmployee = async () => {
    if (!selectedEmployee) return;

    try {
      const employeeRef = doc(db, 'employees', selectedEmployee.id);
      await updateDoc(employeeRef, {
        name: editEmployeeName,
        email: editEmployeeEmail,
        position: editEmployeePosition,
      });

      // Update associated timesheets
      const timesheetsRef = collection(db, 'timesheets');
      const timesheetsQuery = await getDocs(timesheetsRef);
      const updatePromises = timesheetsQuery.docs
        .filter((docSnapshot) => docSnapshot.data().employeeId === selectedEmployee.id)
        .map((docSnapshot) => {
          const timesheetRef = doc(db, 'timesheets', docSnapshot.id);
          return updateDoc(timesheetRef, {
            employeeName: editEmployeeName, // Update name in timesheets
          });
        });

      await Promise.all(updatePromises); // Wait for all updates to finish

      const updatedEmployees = employees.map((employee) =>
        employee.id === selectedEmployee.id
          ? {
              ...employee,
              name: editEmployeeName,
              email: editEmployeeEmail,
              position: editEmployeePosition,
            }
          : employee
      );

      setEmployees(updatedEmployees); // Update local state
      setShowEditEmployeeModal(false);
      toast.success('Employee updated successfully!');
    } catch (error) {
      console.error('Error updating employee:', error);
      toast.error('Failed to update employee. Please try again.');
    }
  };

  // Handle showing the delete confirmation modal
  const handleShowDeleteConfirm = (employee) => {
    setSelectedEmployee(employee);
    setShowDeleteConfirmation(true);
  };

  // Handle confirming deletion of an employee
  const handleConfirmDelete = async () => {
    if (!selectedEmployee) return;

    try {
      const employeeRef = doc(db, 'employees', selectedEmployee.id);
      await deleteDoc(employeeRef);
      setEmployees(employees.filter((employee) => employee.id !== selectedEmployee.id)); // Update local state
      setShowDeleteConfirmation(false);
      toast.success('Employee deleted successfully!');
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('Failed to delete employee. Please try again.');
    }
  };

  // Handle manual hours submission
  const handleManualHoursSubmit = async () => {
    let parsedWorkedHours = parseFloat(workedHours);

    if (isNaN(parsedWorkedHours) || parsedWorkedHours < 0) {
      toast.error('Please enter a valid number of hours.');
      return;
    }

    if (!selectedEmployee) {
      toast.error('Please select an employee.');
      return;
    }

    const workDays = isSpecificDay ? [selectedDay] : calculateDaysBetween(startDate, endDate);

    try {
      for (let i = 0; i < workDays.length; i++) {
        const day = workDays[i];
        const logDate = format(day, 'yyyy-MM-dd');
        const currentBreakTime = i === 0 || repeatBreakTime ? parseFloat(breakTime) || 0 : 0;
        const currentOvertime = i === 0 || repeatOvertime ? parseFloat(overtime) || 0 : 0;

        await addDoc(collection(db, 'timesheets'), {
          employeeId: selectedEmployee.id,
          employeeName: selectedEmployee.name,
          clockIn: 'Manual',
          clockOut: 'Manual',
          workedHours: parsedWorkedHours.toFixed(2),
          breakTime: currentBreakTime,
          overtime: currentOvertime,
          approved: approved,
          logDate: logDate,
        });

        console.log(`Successfully logged for: ${logDate}`);
      }

      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
      resetModalFields();
      toast.success('Manual hours logged successfully!');
    } catch (error) {
      console.error('Error logging manual hours: ', error);
      toast.error('Failed to log manual hours. Please try again.');
    }

    setShowEmployeeDetailsModal(false);
  };

  // Handle clock-in/clock-out submission
  const handleClockInOutSubmit = async () => {
    if (
      !clockInHour ||
      !clockInMinute ||
      !clockInPeriod ||
      !clockOutHour ||
      !clockOutMinute ||
      !clockOutPeriod
    ) {
      toast.error('Please provide valid clock-in and clock-out times.');
      return;
    }

    const workDays = isSpecificDay ? [selectedDay] : calculateDaysBetween(startDate, endDate);

    try {
      for (let i = 0; i < workDays.length; i++) {
        const day = workDays[i];
        const logDate = format(day, 'yyyy-MM-dd');
        const currentBreakTime = i === 0 || repeatBreakTime ? parseFloat(breakTime) || 0 : 0;
        const currentOvertime = i === 0 || repeatOvertime ? parseFloat(overtime) || 0 : 0;

        await addDoc(collection(db, 'timesheets'), {
          employeeId: selectedEmployee.id,
          employeeName: selectedEmployee.name,
          clockIn: `${clockInHour}:${clockInMinute} ${clockInPeriod}`,
          clockOut: `${clockOutHour}:${clockOutMinute} ${clockOutPeriod}`,
          breakTime: currentBreakTime,
          overtime: currentOvertime,
          approved: approved,
          logDate: logDate,
        });

        console.log(`Logged clock-in/out for: ${logDate}`);
      }

      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
      resetModalFields();
      toast.success('Clock-in/out times logged successfully!');
    } catch (error) {
      console.error('Error logging clock-in/clock-out times: ', error);
      toast.error('Failed to log clock-in/out times. Please try again.');
    }

    setShowEmployeeDetailsModal(false);
  };

  // Reset modal fields
  const resetModalFields = () => {
    setBreakTime('');
    setApproved(false);
    setOvertime('');
    setClockInHour('');
    setClockInMinute('');
    setClockInPeriod('');
    setClockOutHour('');
    setClockOutMinute('');
    setClockOutPeriod('');
    setIsSpecificDay(true);
    setSelectedDay(new Date());
    setStartDate(null);
    setEndDate(null);
    setManualHours(false);
    setRepeatBreakTime(false);
    setRepeatOvertime(false);
    setWorkedHours('');
  };

  // Main submission handler to determine which process to use
  const handleSubmitTimes = () => {
    if (manualHours) {
      handleManualHoursSubmit();
    } else {
      handleClockInOutSubmit();
    }
  };

  // Generate day of the week options with dates (today + next 6 days)
 // Generate day of the week options with dates (today + next 6 days)
const generateDayOptions = () => {
  const days = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = addDays(new Date(), i); // Start from today
    days.push({
      dayLabel: format(currentDay, 'EEEE'),
      dateLabel: format(currentDay, 'MM/dd/yyyy'),
      fullLabel: format(currentDay, 'EEEE (MM/dd/yyyy)'),
      value: currentDay,
    });
  }
  return days.map((day, index) => (
    <option key={index} value={day.value.toISOString()}>
      {day.fullLabel}
    </option>
  ));
};


  // Filter and sort employees based on search term and sort option
  const filteredEmployees = employees
    .filter(
      (employee) =>
        employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOption === 'name') {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

  const renderEmployeeCards = () => {
    if (isLoading) {
      return (
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      );
    }

    if (filteredEmployees.length === 0) {
      return (
        <Col className="text-center">
          <p>No employees found.</p>
        </Col>
      );
    }

    return filteredEmployees.map((employee) => (
      <Col key={employee.id} md={4} className="mb-4">
        <div
          className="dashboard-client-container"
          style={{
            cursor: 'pointer',
            position: 'relative',
            paddingBottom: '60px',
          }}
          onClick={() => {
            setSelectedEmployee(employee);
            setShowEmployeeDetailsModal(true);
          }}
        >
          <h3>{employee.name}</h3>
          <hr className="divider-card" />
          <div className="mt-2">
            <p className="client-info-title">Position</p>
            <p className="client-info-content">
              {employee.position || 'No position available'}
            </p>
          </div>
          <div className="mt-2">
            <p className="client-info-title">Email</p>
            <p className="client-info-content">
              {employee.email || 'No email available'}
            </p>
          </div>
          <hr className="divider-card mb-2" />
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
size="md" // Match button size to invoice table
className='me-2 internal-buttons'              onClick={(e) => {
                e.stopPropagation();
                handleEditEmployee(employee);
              }}
            >
              Edit Employee
            </Button>
            <Button
size="md" // Match button size to invoice table
className='me-2 internal-delete-buttons'
variant="danger"
              onClick={(e) => {
                e.stopPropagation();
                handleShowDeleteConfirm(employee);
              }}
            >
              <FaTrash className="mr-2" />
              Delete
            </Button>
          </div>
        </div>
      </Col>
    ));
  };

  return (
    <Container fluid className="timecard-container">
      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // Customize toast appearance to match Dashboard's design
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />

      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Employee Timecards</h1>
        </Col>
        <Col className="text-end">
          <Button className="add-employee-btn" onClick={() => setShowAddEmployeeModal(true)}>
            + Add Employee
          </Button>
        </Col>
      </Row>

      <hr className="divider" />

      {showConfirmation && (
        <div className="confirmation-banner">
          <p>Time logged successfully for {selectedEmployee?.name}!</p>
        </div>
      )}

      {/* Search and Sort Section */}
      <Row className="mb-3">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Search by employee name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col className="text-end d-flex justify-content-end align-items-center">
          <InputGroup className="ms-2" style={{ width: '200px' }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faFilter} />
            </InputGroup.Text>
            <Form.Select
              defaultValue=""
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="">Default Filter</option>
              <option value="name">Name (A-Z)</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <Row>{renderEmployeeCards()}</Row>

      {/* Add Employee Modal */}
      <Modal size="lg" show={showAddEmployeeModal} onHide={() => setShowAddEmployeeModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Add New Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formEmployeeName">
              <Form.Label className="compact-label modal-body-text">Employee Name</Form.Label>
              <Form.Control
                type="text"
                value={newEmployeeName}
                className="all-modal-form-control"
                onChange={(e) => setNewEmployeeName(e.target.value)}
                placeholder="Enter employee name"
              />
            </Form.Group>
            <Form.Group controlId="formEmployeeEmail" className="mt-3">
              <Form.Label className="compact-label modal-body-text">Employee Email</Form.Label>
              <Form.Control
                type="email"
                value={newEmployeeEmail}
                onChange={(e) => setNewEmployeeEmail(e.target.value)}
                className="all-modal-form-control"
                placeholder="Enter employee email"
              />
            </Form.Group>
            <Form.Group controlId="formEmployeePosition" className="mt-3">
              <Form.Label className="compact-label modal-body-text">Employee Position</Form.Label>
              <Form.Control
                type="text"
                value={newEmployeePosition}
                onChange={(e) => setNewEmployeePosition(e.target.value)}
                className="all-modal-form-control"
                placeholder="Enter employee position"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddEmployeeModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddEmployee}>
            Add Employee
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Employee Modal */}
      <Modal size="lg" show={showEditEmployeeModal} onHide={() => setShowEditEmployeeModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formEditEmployeeName">
              <Form.Label className="compact-label modal-body-text">Employee Name</Form.Label>
              <Form.Control
                type="text"
                value={editEmployeeName}
                className="all-modal-form-control"
                onChange={(e) => setEditEmployeeName(e.target.value)}
                placeholder="Enter employee name"
              />
            </Form.Group>
            <Form.Group controlId="formEditEmployeeEmail" className="mt-3">
              <Form.Label className="compact-label modal-body-text">Employee Email</Form.Label>
              <Form.Control
                type="email"
                value={editEmployeeEmail}
                className="all-modal-form-control"
                onChange={(e) => setEditEmployeeEmail(e.target.value)}
                placeholder="Enter employee email"
              />
            </Form.Group>
            <Form.Group controlId="formEditEmployeePosition" className="mt-3">
              <Form.Label className="compact-label modal-body-text">Employee Position</Form.Label>
              <Form.Control
                type="text"
                value={editEmployeePosition}
                className="all-modal-form-control"
                onChange={(e) => setEditEmployeePosition(e.target.value)}
                placeholder="Enter employee position"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowEditEmployeeModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditEmployee}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Employee Details Modal (Time Logging) */}
      {selectedEmployee && (
        <Modal
          size="lg"
          show={showEmployeeDetailsModal}
          onHide={() => setShowEmployeeDetailsModal(false)}
        >
          <Modal.Header closeButton className="employee-log-modal-header">
            <Modal.Title className="employee-log-modal-title">
              {selectedEmployee.name}'s Time Log
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="employee-log-modal-body">
            <Form className="employee-log-form">
              <Row className="compact-row mt-2">
                <Col>
                  <Form.Check
                    type="switch"
                    label="Use Specific Day of the Week"
                    checked={isSpecificDay}
                    onChange={(e) => setIsSpecificDay(e.target.checked)}
                    className="compact-toggle"
                  />
                </Col>
              </Row>

              {/* Date Selection Section */}
              {!isSpecificDay ? (
                <>
                  <Row className="compact-row mt-2">
                    <Col>
                      <h6 className="compact-label modal-body-text">Start Date</h6>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="date-picker-input"
                        placeholderText="Start Date"
                      />
                    </Col>
                    <Col>
                      <h6 className="compact-label modal-body-text">End Date</h6>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="date-picker-input"
                        placeholderText="End Date"
                      />
                    </Col>
                  </Row>
                  <Row className="compact-row mt-2">
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Repeat Break Time for each day"
                        checked={repeatBreakTime}
                        onChange={(e) => setRepeatBreakTime(e.target.checked)}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Repeat Overtime for each day"
                        checked={repeatOvertime}
                        onChange={(e) => setRepeatOvertime(e.target.checked)}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="compact-row mt-2">
                  <Col>
                    <h6 className="compact-label modal-body-text">Day of the Week</h6>
<Form.Select
 value={selectedDay.toISOString()}
 onChange={(e) => setSelectedDay(new Date(e.target.value))}
 className="compact-input"
>
  <option value="" disabled>
    Select a day
  </option>
  {generateDayOptions()}
</Form.Select>

                  </Col>
                </Row>
              )}

              <Row className="compact-row mt-3">
                <Col>
                  <Form.Check
                    type="switch"
                    label="Log Hours Manually"
                    checked={manualHours}
                    onChange={(e) => setManualHours(e.target.checked)}
                    className="compact-label modal-body-text"
                  />
                </Col>
              </Row>

              {!manualHours ? (
                <>
                  <Row className="compact-row mt-2">
                    <Col>
                      <h6 className="compact-label modal-body-text">Clock In Time</h6>
                      <div className="compact-time-inputs">
                        <Form.Select
                          value={clockInHour}
                          onChange={(e) => setClockInHour(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            Hour
                          </option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={clockInMinute}
                          onChange={(e) => setClockInMinute(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            Minute
                          </option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Select>
                        <Form.Select
                          value={clockInPeriod}
                          onChange={(e) => setClockInPeriod(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            AM/PM
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col>
                      <h6 className="compact-label modal-body-text">Clock Out Time</h6>
                      <div className="compact-time-inputs">
                        <Form.Select
                          value={clockOutHour}
                          onChange={(e) => setClockOutHour(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            Hour
                          </option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={clockOutMinute}
                          onChange={(e) => setClockOutMinute(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            Minute
                          </option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Select>
                        <Form.Select
                          value={clockOutPeriod}
                          onChange={(e) => setClockOutPeriod(e.target.value)}
                          className="time-select"
                        >
                          <option value="" disabled>
                            AM/PM
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="compact-row mt-3">
                  <Col>
                    <h6 className="compact-label modal-body-text">Hours Worked</h6>
                    <Form.Control
                      type="number"
                      value={workedHours}
                      onChange={(e) => setWorkedHours(e.target.value)}
                      className="compact-input"
                      placeholder="Hours Worked"
                      min="0"
                    />
                  </Col>
                </Row>
              )}

              <Row className="compact-row mt-3">
                <Col>
                  <h6 className="compact-label modal-body-text">Break Time (min)</h6>
                  <Form.Control
                    type="number"
                    value={breakTime}
                    onChange={(e) => setBreakTime(e.target.value)}
                    className="compact-input"
                    placeholder="Break Time"
                    min="0"
                  />
                </Col>
                <Col>
                  <h6 className="compact-label modal-body-text">Overtime (hours)</h6>
                  <Form.Control
                    type="number"
                    value={overtime}
                    onChange={(e) => setOvertime(e.target.value)}
                    className="compact-input"
                    placeholder="Overtime"
                    min="0"
                  />
                </Col>
              </Row>

              <Row className="compact-row mt-3">
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Approve Timesheet"
                    checked={approved}
                    onChange={(e) => setApproved(e.target.checked)}
                    className="compact-checkbox"
                  />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer className="employee-log-modal-footer">
            <Button variant="secondary" onClick={() => setShowEmployeeDetailsModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmitTimes}>
              Submit Times
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton className="employee-log-modal-header">
          <Modal.Title
            style={{
              fontWeight: '550',
              letterSpacing: '1px',
              fontFamily: 'Arial, Helvetica, sans-serif',
              color: '#333',
            }}
          >
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-2 mb-2 confirm-delete">
          Are you sure you want to delete{' '}
          <span className="confirm-name">{selectedEmployee?.name}?</span>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmployeeTimecard;