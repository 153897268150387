import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client'; // Ensure you're importing from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Get the root element from the HTML file
const rootElement = document.getElementById('root');

// Create a root using React 18's createRoot API
const root = ReactDOM.createRoot(rootElement);

// Render the App inside the root element
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: to log web vitals (performance) if needed
reportWebVitals();
