// Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faClock,
  faList,
  faUsers,
  faUsersCog,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Authentication
import '../styles/Sidebar.css'; // Custom sidebar CSS

const Sidebar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Listen to authentication state changes
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Set the user data when logged in
      }
    });
    return unsubscribe; // Cleanup on component unmount
  }, []);

  const handleProfileClick = () => {
    navigate('/admin/profile'); // Navigate to profile page
  };

  return (
    <div className="custom-sidebar vh-100 position-fixed">
      <Navbar expand="lg" className="flex-column vh-100 sticky-top">
        <Container className="flex-column align-items-start p-0">
          <Navbar.Brand className="w-100 p-0 sidebar-header">
            <h1 className="sidebar-title">Imagine Seniorcare</h1>
          </Navbar.Brand>
          <hr className="sidebar-divider" />

          <Nav className="flex-column w-100 custom-nav p-0">
            <Nav.Link
              as={NavLink}
              to="/admin/dashboard"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faTachometerAlt} className="me-2 fa-icon" />
              Dashboard
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/employee-timecard"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faClock} className="me-2 fa-icon" />
              Employee Timecard
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/timesheets"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faList} className="me-2 fa-icon" />
              Timesheets
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/applications"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faUsers} className="me-2 fa-icon" />
              Applications
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/contracts"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faUsersCog} className="me-2 fa-icon" />
              Contracts
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/invoices"
              className="d-flex align-items-center nav-link-custom"
            >
              <FontAwesomeIcon icon={faChartLine} className="me-2 fa-icon" />
              Invoices
            </Nav.Link>
          </Nav>

          {/* Display the logged-in user's profile image and name */}
          {user && (
            <div className="sidebar-profile d-flex align-items-center" onClick={handleProfileClick}>
              <div className="profile-image-container">
                <img
                  src={user.photoURL || '/default-profile.png'}
                  alt={user.displayName}
                  className="sidebar-profile-img"
                />
              </div>
              <p className="sidebar-profile-name mb-0 ms-2">{user.displayName}</p>
            </div>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default Sidebar;
