import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  FormControl,
  Spinner,
} from 'react-bootstrap';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faThLarge,
  faFilter,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CreateInvoice from './CreateInvoice';
import { toast, ToastContainer } from 'react-toastify'; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [clientStayStart, setClientStayStart] = useState(new Date());
  const [clientStayEnd, setClientStayEnd] = useState(new Date());
  const [newClientName, setNewClientName] = useState('');
  const [newClientPhone, setNewClientPhone] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [viewType, setViewType] = useState('row');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const [clientsSnapshot, employeesSnapshot] = await Promise.all([
          getDocs(collection(db, 'clients')),
          getDocs(collection(db, 'employees')),
        ]);

        const clientsData = clientsSnapshot.docs.map((doc) => {
          const client = doc.data();
          return {
            id: doc.id,
            ...client,
            stayStart: client.stayStart ? new Date(client.stayStart) : null,
            stayEnd: client.stayEnd ? new Date(client.stayEnd) : null,
          };
        });

        setClients(clientsData);

        const employeesData = employeesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setEmployees(employeesData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch data. Please try again.'); // Using toast
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchData();
  }, []);

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setNewClientName(client.name);
    setNewClientPhone(client.phone);
    setNewClientEmail(client.email);
    setClientStayStart(client.stayStart ? new Date(client.stayStart) : new Date());
    setClientStayEnd(client.stayEnd ? new Date(client.stayEnd) : new Date());
    setSelectedEmployee(
      employees.find((emp) => emp.name === client.assignedEmployee) || null
    );
    setShowEditClientModal(true);
  };

  const handleSaveEditClient = async () => {
    if (!selectedClient) return;

    try {
      const clientRef = doc(db, 'clients', selectedClient.id);
      await updateDoc(clientRef, {
        name: newClientName,
        phone: newClientPhone,
        email: newClientEmail,
        stayStart: clientStayStart.toISOString(),
        stayEnd: clientStayEnd.toISOString(),
        assignedEmployee: selectedEmployee
          ? selectedEmployee.name
          : 'No Employee Assigned',
      });

      const updatedClients = clients.map((client) =>
        client.id === selectedClient.id
          ? {
              ...client,
              name: newClientName,
              phone: newClientPhone,
              email: newClientEmail,
              stayStart: clientStayStart,
              stayEnd: clientStayEnd,
              assignedEmployee: selectedEmployee
                ? selectedEmployee.name
                : 'No Employee Assigned',
            }
          : client
      );

      setClients(updatedClients);
      setShowEditClientModal(false);
      toast.success('Client updated successfully!'); // Using toast
    } catch (error) {
      console.error('Error updating client:', error);
      toast.error('Failed to update client. Please try again.'); // Using toast
    }
  };

  const handleAddClient = () => {
    setNewClientName('');
    setNewClientPhone('');
    setNewClientEmail('');
    setClientStayStart(new Date());
    setClientStayEnd(new Date());
    setSelectedEmployee(null);
    setShowAddClientModal(true);
  };

  const handleSaveNewClient = async () => {
    if (!newClientName || !newClientPhone || !newClientEmail) {
      toast.error('Please fill out all fields'); // Using toast
      return;
    }

    try {
      await addDoc(collection(db, 'clients'), {
        name: newClientName,
        phone: newClientPhone,
        email: newClientEmail,
        stayStart: clientStayStart.toISOString(),
        stayEnd: clientStayEnd.toISOString(),
        assignedEmployee: selectedEmployee
          ? selectedEmployee.name
          : 'No Employee Assigned',
      });

      // Fetch the updated list of clients
      const querySnapshot = await getDocs(collection(db, 'clients'));
      const clientsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        stayStart: doc.data().stayStart ? new Date(doc.data().stayStart) : null,
        stayEnd: doc.data().stayEnd ? new Date(doc.data().stayEnd) : null,
      }));
      setClients(clientsData);

      setShowAddClientModal(false);
      toast.success('Client added successfully!'); // Using toast
    } catch (error) {
      console.error('Error adding client:', error);
      toast.error('Failed to add client. Please try again.'); // Using toast
    }
  };

  const handleShowDeleteConfirm = (client) => {
    setClientToDelete(client);
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    if (clientToDelete) {
      try {
        const clientRef = doc(db, 'clients', clientToDelete.id);
        await deleteDoc(clientRef);
        setClients(clients.filter((client) => client.id !== clientToDelete.id));
        setShowDeleteConfirmModal(false);
        toast.success('Client deleted successfully!'); // Using toast
      } catch (error) {
        console.error('Error deleting client:', error);
        toast.error('Failed to delete client. Please try again.'); // Using toast
      }
    }
  };

  const getSortedClients = () => {
    let sortedClients = [...clients];

    if (sortOption === 'name') {
      sortedClients.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === 'uploadDate') {
      sortedClients.sort(
        (a, b) => new Date(b.stayStart) - new Date(a.stayStart)
      );
    }

    return sortedClients;
  };

  const handleViewSwitch = (view) => {
    setViewType(view);
  };

  const handleCreateInvoice = (client) => {
    setSelectedClient(client);
    setShowCreateInvoiceModal(true);
  };

  const renderClientRows = () => {
    const filteredClients = getSortedClients().filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredClients.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center no-clients-message">
            No clients found.
          </td>
        </tr>
      );
    }

    return filteredClients.map((client) => {
      const startDateString = client.stayStart
        ? client.stayStart.toLocaleDateString()
        : 'N/A';
      const endDateString = client.stayEnd
        ? client.stayEnd.toLocaleDateString()
        : 'N/A';

      return (
        <tr key={client.id} style={{ cursor: 'pointer' }}>
          <td onClick={() => handleEditClient(client)}>{client.name}</td>
          <td onClick={() => handleEditClient(client)}>
            {client.phone || 'N/A'}
          </td>
          <td onClick={() => handleEditClient(client)}>{client.email}</td>
          <td onClick={() => handleEditClient(client)}>
            {client.assignedEmployee || 'None'}
          </td>
          <td onClick={() => handleEditClient(client)}>
            {`${startDateString} - ${endDateString}`}
          </td>
          <td className="text-center">
            <Button
              variant="primary"
              size="md" // Match button size to invoice table
              className='me-2 internal-buttons'
              onClick={(e) => {
                e.stopPropagation();
                handleCreateInvoice(client);
              }}
            >
              Create Invoice 
            </Button>
            <Button
              size="md" // Match button size to invoice table
              className='me-2 internal-delete-buttons'
              variant="danger"
              onClick={(e) => {
                e.stopPropagation();
                handleShowDeleteConfirm(client);
              }}
              title="Delete Client"
            >
              <FontAwesomeIcon icon={faTrash} color="white" />
              <span className='ml-2 square-button'>Delete</span>
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderClientCards = () => {
    const filteredClients = getSortedClients().filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredClients.length === 0) {
      return (
        <Col className="text-center w-100">
          <p className="no-clients-message">No clients found.</p>
        </Col>
      );
    }

    return filteredClients.map((client) => {
      const startDateString = client.stayStart
        ? client.stayStart.toLocaleDateString()
        : 'N/A';
      const endDateString = client.stayEnd
        ? client.stayEnd.toLocaleDateString()
        : 'N/A';

      return (
        <Col key={client.id} md={4} className="mb-4">
          <div
            className="dashboard-client-container"
            style={{ cursor: 'pointer' }}
            onClick={() => handleEditClient(client)}
          >
            <h3>{client.name}</h3>
            <hr className="divider-card" />
            <div>
              <p className="client-info-title">Phone Number</p>
              <p className="client-info-content">
                {client.phone || 'No phone number available'}
              </p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Email</p>
              <p className="client-info-content">{client.email}</p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Assigned Employee</p>
              <p className="client-info-content">
                {client.assignedEmployee || 'None'}
              </p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Duration of Stay</p>
              <p className="client-info-content">
                {`${startDateString} - ${endDateString}`}
              </p>
            </div>
            <hr className="divider-card" />

            <div className="mt-4 d-flex justify-content-between">
              <Button
                variant="primary"
                size="md" // Match button size to invoice table
                className='me-2 internal-buttons'
                onClick={(e) => {
                  e.stopPropagation();
                  handleCreateInvoice(client);
                }}
              >
                Create Invoice 
              </Button>
              <Button
                variant="danger"
                size="md" // Match button size to invoice table
                className='me-2 internal-delete-buttons'
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowDeleteConfirm(client);
                }}
                title="Delete Client"
              >
                <FontAwesomeIcon icon={faTrash} color="white" className='mr-2' />
                Delete
              </Button>
            </div>
          </div>
        </Col>
      );
    });
  };

  const renderRowView = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Assigned Employee</th>
          <th>Duration of Stay</th>
          <th style={{ width: "280px" }}>Actions</th>
        </tr>
      </thead>
      <tbody>{renderClientRows()}</tbody>
    </Table>
  );

  const renderCardView = () => (
    <Row>
      {renderClientCards()}
    </Row>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Row className="justify-content-center">
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      );
    }

    if (viewType === 'row') {
      return renderRowView();
    } else if (viewType === 'card') {
      return renderCardView();
    }
  };

  return (
    <Container fluid className="timecard-container">
      {/* Header Section */}
      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Client Dashboard</h1>
        </Col>
        <Col className="text-end">
          <Button className="dashboard-add-client-btn" onClick={handleAddClient}>
            + Add Client
          </Button>
        </Col>
      </Row>

      <hr className="divider" />

      {/* Search and View Controls */}
      <Row className="mb-4 align-items-center">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Search by client name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col className="text-end d-flex justify-content-end align-items-center">
          <Button
            variant="light"
            className={`view-icon-button custom-width-button ms-2 ${
              viewType === 'row' ? 'active square-button' : ''
            }`}
            onClick={() => handleViewSwitch('row')}
            title="Row View"
          >
            <FontAwesomeIcon icon={faList} className='mr-2' />
            Row View
          </Button>

          <Button
            variant="light"
            className={`view-icon-button custom-width-button ms-2 ${
              viewType === 'card' ? 'active square-button' : ''
            }`}
            onClick={() => handleViewSwitch('card')}
            title="Card View"
          >
            <FontAwesomeIcon icon={faThLarge} className='mr-2' />
            Card View
          </Button>

          <InputGroup className="ms-2" style={{ width: '200px' }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faFilter} />
            </InputGroup.Text>
            <Form.Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="">Default Filter</option>
              <option value="name">Name (A-Z)</option>
              <option value="uploadDate">Log Date (Newest First)</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      {/* Client List */}
      {renderContent()}

      {/* Add Client Modal */}
      <Modal size="lg" show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Add New Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="text"
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                placeholder="Enter client's name"
              />
            </Form.Group>
            <Form.Group controlId="formClientPhone" className="mt-3">
              <Form.Label>Client Phone Number</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="text"
                value={newClientPhone}
                onChange={(e) => setNewClientPhone(e.target.value)}
                placeholder="Enter client's phone number"
              />
            </Form.Group>
            <Form.Group controlId="formClientEmail" className="mt-3">
              <Form.Label>Client Email</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="email"
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                placeholder="Enter client's email"
              />
            </Form.Group>
            <Form.Group controlId="formAssignedEmployee" className="mt-3">
              <Form.Label>Assign Employee</Form.Label>
              <Form.Select
                value={selectedEmployee ? selectedEmployee.id : ''}
                onChange={(e) =>
                  setSelectedEmployee(
                    employees.find((emp) => emp.id === e.target.value) || null
                  )
                }
              >
                <option value="">None</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formClientStay" className="mt-4">
              <Form.Label className='stay-date'>Stay Duration</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={clientStayStart}
                    onChange={(date) => setClientStayStart(date)}
                    className='all-modal-form-control'
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
                <div>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={clientStayEnd}
                    onChange={(date) => setClientStayEnd(date)}
                    className='all-modal-form-control'
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowAddClientModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewClient}>
            Add Client
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Client Modal */}
      <Modal size="lg" show={showEditClientModal} onHide={() => setShowEditClientModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Edit Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formEditClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                className='all-modal-form-control'
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                placeholder="Enter client's name"
              />
            </Form.Group>
            <Form.Group controlId="formEditClientPhone" className="mt-3">
              <Form.Label>Client Phone Number</Form.Label>
              <Form.Control
                type="text"
                className='all-modal-form-control'
                value={newClientPhone}
                onChange={(e) => setNewClientPhone(e.target.value)}
                placeholder="Enter client's phone number"
              />
            </Form.Group>
            <Form.Group controlId="formEditClientEmail" className="mt-3">
              <Form.Label>Client Email</Form.Label>
              <Form.Control
                type="email"
                className='all-modal-form-control'
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                placeholder="Enter client's email"
              />
            </Form.Group>
            <Form.Group controlId="formEditAssignedEmployee" className='mt-3'>
              <Form.Label>Assign Employee</Form.Label>
              <Form.Select
                className='all-modal-form-control'
                value={selectedEmployee ? selectedEmployee.id : ''}
                onChange={(e) =>
                  setSelectedEmployee(
                    employees.find((emp) => emp.id === e.target.value) || null
                  )
                }
              >
                <option value="">None</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formEditClientStay" className="mt-4">
              <Form.Label className='stay-date'>Stay Duration</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={clientStayStart}
                    className='all-modal-form-control'
                    onChange={(date) => setClientStayStart(date)}
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
                <div>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={clientStayEnd}
                    onChange={(date) => setClientStayEnd(date)}
                    className='all-modal-form-control'
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowEditClientModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditClient}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Create Invoice Modal */}
      {showCreateInvoiceModal && selectedClient && (
        <CreateInvoice
          show={showCreateInvoiceModal}
          onClose={() => setShowCreateInvoiceModal(false)}
          client={selectedClient}
        />
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
      >
        <Modal.Header closeButton className="employee-log-modal-header">
          <Modal.Title style={{ fontWeight: '550', letterSpacing: '1px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#333' }}>
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <p className='mt-2 mb-2 '>Are you sure you want to delete <strong>{clientToDelete?.name}?</strong></p>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete Client
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // Customize toast appearance to match Dashboard's design
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />
    </Container>
  );
};

export default Dashboard;