import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserNurse, faHandHoldingHeart, faCar, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Modal, Form, Button } from 'react-bootstrap';
import '../styles/ServicesSection.css';

const ServicesSection = () => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [state, handleSubmit] = useForm("mkgwyabl"); // Formspree form ID

  const handleStarClick = (stars) => {
    setRating(stars);
    if (stars < 5) {
      setShowModal(true);
    } else {
      window.location.href = 'https://g.page/r/CQCjw-IxptZTEBM/review';
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setRating(0); // Reset the rating
    setHoverRating(0); // Reset the hover state
    setFormSubmitted(false); // Reset the submission state
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Show the thank you message immediately
    setFormSubmitted(true);

    // Reset star ratings
    setRating(0);
    setHoverRating(0);

    // Handle the form submission
    await handleSubmit(event);

    // No automatic modal close; the modal will remain open for manual closure.
  };

  return (
    <section className="bsb-service-6 py-5 services-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7">
            <h2 className="services-header">Our Services</h2>
            <hr className="w-50 mx-auto mb-5 border-dark-subtle" />
          </div>
        </div>
        <div className="container">
          <div className="row gy-4 gx-5">
            <div className="col-12 col-md-4">
              <div className="card bg-transparent border-primary rounded-0">
                <div className="card-body">
                  <FontAwesomeIcon icon={faUserNurse} className="service-icon text-primary mb-4" />
                  <h3 className="h2 mb-4">Fully Trained In-Home Caregivers/Sitters</h3>
                  <p className="mb-4 text-secondary">Our fully trained in-home caregivers and sitters provide comprehensive assistance for daily living tasks, ensuring your loved ones receive the best care possible.</p>
                  <Link to="/services" className="fw-bold text-decoration-none link-primary serv-section1">
                    Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    <span className="visually-hidden"> about our Fully Trained In-Home Caregivers and Sitters services, providing comprehensive daily living assistance.</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card bg-transparent border-primary rounded-0">
                <div className="card-body">
                  <FontAwesomeIcon icon={faHandHoldingHeart} className="service-icon text-primary mb-4" />
                  <h3 className="h2 mb-4">Complete Assistance in Daily Living/Task</h3>
                  <p className="mb-4 text-secondary">We provide complete assistance in daily living tasks, helping you maintain your independence and quality of life.</p>
                  <Link to="/services" className="fw-bold text-decoration-none link-primary serv-section1">
                    Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    <span className="visually-hidden"> about our Complete Assistance in Daily Living and Task services, helping you maintain independence and quality of life.</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card bg-transparent border-primary rounded-0">
                <div className="card-body">
                  <FontAwesomeIcon icon={faCar} className="service-icon text-primary mb-4" />
                  <h3 className="h2 mb-4">Safe and Reliable Transportation</h3>
                  <p className="mb-4 text-secondary">Our services include providing safe and reliable transportation for medical appointments, ensuring you get to your destinations comfortably and on time.</p>
                  <Link to="/services" className="fw-bold text-decoration-none link-primary">
                    Learn More
                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    <span className="visually-hidden"> about our Safe and Reliable Transportation services for medical appointments, ensuring comfort and timeliness.</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Review Section */}
        <div className="row mt-5 justify-content-center">
          <div className="col-12 text-center">
            <h2 className="services-header">Rate Our Services</h2>
            <hr className="w-50 mx-auto mb-5 border-dark-subtle" />
            <div className="stars">
              {[1, 2, 3, 4, 5].map((star) => (
                <FontAwesomeIcon
                  key={star}
                  icon={faStar}
                  className={`review-star ${star <= (hoverRating || rating) ? 'text-warning' : 'text-muted'}`}
                  onMouseEnter={() => setHoverRating(star)}
                  onMouseLeave={() => setHoverRating(0)}
                  onClick={() => handleStarClick(star)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Feedback Modal */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>How can we improve?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSubmitted ? (
            <p>Thank you for your feedback! We appreciate your input and will use it to improve our services.</p>
          ) : (
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Your Full Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter your name" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Reason for Feedback</Form.Label>
                <Form.Control type="text" name="reason" placeholder="Reason" required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Your Feedback</Form.Label>
                <Form.Control as="textarea" name="feedback" rows={3} placeholder="Please let us know how we can improve..." required />
              </Form.Group>
              <Button variant="primary" type="submit" className="modal-btn"disabled={state.submitting}>
                Submit
              </Button>
              <ValidationError field="email" errors={state.errors} />
              <ValidationError field="message" errors={state.errors} />
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ServicesSection;
