import React, { useState, useEffect } from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection, serverTimestamp, getDocs } from "firebase/firestore"; // Updated import
import { storage, db } from "../../firebase"; 

const ClientUploadFile = ({ fetchData, folders }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [customFileName, setCustomFileName] = useState("");
  const [clients, setClients] = useState([]); // State to hold clients
  const [selectedClient, setSelectedClient] = useState(""); // State for selected client

  useEffect(() => {
    // Fetch clients when modal is opened
    if (showModal) {
      const fetchClients = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "clients"));
          const clientsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setClients(clientsData);
        } catch (error) {
          console.error("Error fetching clients:", error);
          toast.error("Failed to fetch clients.");
        }
      };

      fetchClients();
    }
  }, [showModal]);

  const handleFileUpload = (e) => {
    e.preventDefault();
    if (!file) return toast.error("Please select a file!");

    const fileName = customFileName || file.name;
    const storageRef = ref(storage, `clientContracts/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(prog);
      },
      (error) => toast.error("Error uploading file: " + error.message),
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        try {
          const fileData = {
            fileName,
            fileUrl: url,
            mimeType: file.type,
            isFolder: false,
            folderId: selectedFolder || "main",
            uploadDate: serverTimestamp(),
            clientId: selectedClient, // Add selected client ID here
          };

          await addDoc(collection(db, "contracts"), fileData);
          toast.success("Contract uploaded successfully!");
          setFile(null);
          setProgress(0);
          setShowModal(false);
          setCustomFileName("");
          setSelectedClient(""); // Reset selected client
          fetchData();
        } catch (error) {
          toast.error("Error saving file data: " + error.message);
        }
      }
    );
  };

  return (
    <>
      {/* Upload button to open modal */}
      <Button
        className="filter-button ml-2 dashboard-add-client-btn custom-width-button"
        onClick={() => setShowModal(true)}
        variant="primary"
        >
        + Upload Contract
      </Button>

      {/* Modal for file upload */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={handleFileUpload}>
          <Modal.Header closeButton className="employee-log-modal-header">
            <Modal.Title className="employee-log-modal-title">Upload Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body className="employee-log-modal-body">
            {/* Progress bar for upload progress */}
            {progress > 0 && (
              <ProgressBar now={progress} label={`${progress}%`} className="mb-3" />
            )}

            {/* File selection input */}
            <Form.Group>
              <Form.Label>Select Contract File</Form.Label>
              <Form.Control
                type="file"
                accept="application/pdf"
                onChange={(e) => setFile(e.target.files[0])}
                required
                className="same-input-style"
              />
            </Form.Group>

            {/* Custom file name input */}
            <Form.Group className="mt-3">
              <Form.Label>Contract Name (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a custom contract name"
                value={customFileName}
                onChange={(e) => setCustomFileName(e.target.value)}
                className='all-modal-form-control'
              />
            </Form.Group>

            {/* Client selection dropdown */}
            <Form.Group className="mt-3">
              <Form.Label>Select Client</Form.Label>
              <Form.Select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className="same-input-style"
                required
              >
                <option value="" disabled>Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Folder selection dropdown */}
            <Form.Group className="mt-3">
              <Form.Label>Select Folder</Form.Label>
              <Form.Select
                value={selectedFolder}
                onChange={(e) => setSelectedFolder(e.target.value)}
                className="same-input-style"
              >
                <option value="">Main Screen</option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>
                    {folder.folderName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="employee-log-modal-footer">
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!file || progress > 0}
            >
              {progress > 0 ? `Uploading ${progress}%` : 'Upload Contract'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ClientUploadFile;
