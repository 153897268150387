import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Tabs,
  Tab,
  Modal,
  Form,
  InputGroup,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import {
  collection,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  format,
  parseISO,
  isValid,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfWeek,
  endOfMonth,
  endOfYear,
  isSameDay,
} from 'date-fns';
import * as XLSX from 'xlsx';
import {
  faTrash,
  faUser,
  faCalendarAlt,
  faCaretDown,
  faFileExcel,
  faFileCsv,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/TimeSheets.css';

// Import the PDF generation functions
import { generateTimesheetPDF } from '../components/timesheets/timesheetPDF';
import { generateSummaryPDF } from '../components/timesheets/summaryPDF';

// Utility Functions
const parseDateSafe = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return null;
  const date = parseISO(dateStr);
  return isValid(date) ? date : null;
};

const normalizeDate = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return '';
  const date = parseDateSafe(dateStr);
  if (!date) return '';
  return format(date, 'yyyy-MM-dd');
};

const convertTo24HourTime = (time) => {
  if (!time || typeof time !== 'string') return null;
  const [hourMinute, period] = time.split(' ');
  if (!hourMinute || !period) return null;
  let [hours, minutes] = hourMinute.split(':').map(Number);
  if (isNaN(hours) || isNaN(minutes)) return null;
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;
  return { hours, minutes };
};

const convertTo12HourTime = (time) => {
  if (!time || typeof time !== 'string') return [null, null, null];
  const [hourMinute, period] = time.split(' ');
  if (!hourMinute || !period) return [null, null, null];
  let [hours, minutes] = hourMinute.split(':').map(Number);
  if (isNaN(hours) || isNaN(minutes)) return [null, null, null];
  return [hours, minutes.toString().padStart(2, '0'), period];
};

// Memoized Table Row Component
const MemoizedTableRow = React.memo(
  ({
    employee,
    hourlyRates,
    handleHourlyRateChange,
    handleHourlyRateBlur,
    totalPay,
    handleDeleteEmployee,
  }) => {
    return (
      <tr key={employee.employeeName}>
        <td>{employee.employeeName}</td>
        <td>{employee.totalHours} hrs</td>
        <td>{employee.totalOvertime} hrs</td>
        <td>
          <Form.Control
            type="number"
            value={
              hourlyRates[employee.employeeName] !== undefined
                ? hourlyRates[employee.employeeName]
                : employee.ratePerHour
            }
            onChange={(e) =>
              handleHourlyRateChange(employee.employeeName, e.target.value)
            }
            onBlur={() => handleHourlyRateBlur(employee.employeeName)}
            aria-label={`Hourly rate for ${employee.employeeName}`}
            size="md"
            min="0"
            step="0.01"
          />
        </td>
        <td>
          $
          {typeof totalPay[employee.employeeName] === 'number'
            ? totalPay[employee.employeeName].toFixed(2)
            : '0.00'}
        </td>
        <td>
          <Button
            variant="danger"
            className="me-2 internal-delete-buttons"
            size="md"
            onClick={() => handleDeleteEmployee(employee.employeeName)}
            aria-label={`Delete timesheets for ${employee.employeeName}`}
          >
            <FontAwesomeIcon icon={faTrash} color="white" />
            <span className="ml-2 square-button">Delete</span>
          </Button>
        </td>
      </tr>
    );
  }
);

// Memoized SummaryView Component
const SummaryView = React.memo(
  ({
    aggregatedData,
    hourlyRates,
    handleHourlyRateChange,
    handleHourlyRateBlur,
    totalPay,
    handleExportExcel,
    handleExportCSV,
    handleOvertimeRateChange,
    overtimeRate,
    uniqueEmployees,
    employeeFilter,
    setEmployeeFilter,
    timeFilter,
    setTimeFilter,
    dateRange,
    setDateRange,
    handleDeleteEmployee,
    setShowPDFModal,
    isLoading,
  }) => {
    return (
      <div>
        <Row className="mb-4 align-items-center justify-content-end">
          <Col className="d-flex justify-content-end align-items-center">
            <InputGroup className="mr-2">
              <Button
                onClick={handleExportExcel}
                variant="success"
                className="filter-button dashboard-add-client-btn custom-width-button mr-2"
              >
                <FontAwesomeIcon icon={faFileExcel} color="white" />
                <span className="ms-2">Export to Excel</span>
              </Button>
              <Button
                onClick={handleExportCSV}
                variant="success"
                className="filter-button dashboard-add-client-btn custom-width-button"
              >
                <FontAwesomeIcon icon={faFileCsv} color="white" />
                <span className="ms-2">Export to CSV</span>
              </Button>
              <Button
                onClick={() => setShowPDFModal(true)}
                className="filter-button dashboard-add-client-btn custom-width-button ml-2"
                variant="success"
              >
                <FontAwesomeIcon icon={faFilePdf} color="white" />
                <span className="ms-2">Download PDF</span>
              </Button>
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '350px' }}>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} color="black" />
              </InputGroup.Text>
              <Form.Select
                value={employeeFilter}
                onChange={(e) => setEmployeeFilter(e.target.value)}
                aria-label="Filter by Employee"
              >
                <option value="">All Employees</option>
                {uniqueEmployees.map((employee, index) => (
                  <option key={index} value={employee}>
                    {employee}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            <InputGroup className="me-2" style={{ width: '350px' }}>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendarAlt} color="black" />
              </InputGroup.Text>
              <Form.Select
                value={timeFilter}
                onChange={(e) => {
                  setTimeFilter(e.target.value);
                  if (e.target.value !== 'custom') {
                    setDateRange([null, null]);
                  }
                }}
                aria-label="Filter by Time Range"
              >
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
                <option value="custom">Custom Range</option>
                <option value="all">All Time</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>

        {timeFilter === 'custom' && (
          <Row className="mb-3 justify-content-start">
            <InputGroup className="d-flex" style={{ width: '400px' }}>
              <Col>
                <DatePicker
                  selected={dateRange[0]}
                  onChange={(startDate) =>
                    setDateRange([startDate, dateRange[1]])
                  }
                  selectsStart
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  isClearable
                  className="form-control"
                  placeholderText="Start Date"
                  aria-label="Start Date"
                />
              </Col>
              <Col>
                <DatePicker
                  selected={dateRange[1]}
                  onChange={(endDate) => setDateRange([dateRange[0], endDate])}
                  selectsEnd
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  isClearable
                  className="form-control"
                  placeholderText="End Date"
                  aria-label="End Date"
                />
              </Col>
            </InputGroup>
          </Row>
        )}

        {isLoading ? (
          <Row className="justify-content-center">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: '#343a40', color: '#fff' }}>
              <tr>
                <th>Employee Name</th>
                <th>Total Hours Worked</th>
                <th>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-overtime"
                      className="text-decoration-none p-0"
                      style={{ color: 'white', fontWeight: 'bold' }}
                    >
                      Overtime ({overtimeRate}){' '}
                      <FontAwesomeIcon icon={faCaretDown} color="white" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOvertimeRateChange('1x')}>
                        1x
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOvertimeRateChange('1.5x')}>
                        1.5x
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOvertimeRateChange('2x')}>
                        2x
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th>Hourly Wage ($)</th>
                <th>Total Pay ($)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {aggregatedData.map((employee) => (
                <MemoizedTableRow
                  key={employee.employeeName}
                  employee={employee}
                  hourlyRates={hourlyRates}
                  handleHourlyRateChange={handleHourlyRateChange}
                  handleHourlyRateBlur={handleHourlyRateBlur}
                  totalPay={totalPay}
                  handleDeleteEmployee={() => {
                    handleDeleteEmployee(employee.employeeName);
                  }}
                />
              ))}
            </tbody>
            <tfoot style={{ fontWeight: 'bold' }}>
              <tr>
                <td>Total</td>
                <td>
                  {aggregatedData
                    .reduce((sum, emp) => sum + parseFloat(emp.totalHours), 0)
                    .toFixed(2)}{' '}
                  hrs
                </td>
                <td></td>
                <td></td>
                <td>
                  $
                  {aggregatedData
                    .reduce(
                      (sum, emp) => sum + (totalPay[emp.employeeName] || 0),
                      0
                    )
                    .toFixed(2)}
                </td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        )}
      </div>
    );
  }
);

const TimeSheets = () => {
  const [timeSheets, setTimeSheets] = useState([]);
  const [filteredTimeSheets, setFilteredTimeSheets] = useState([]);
  const [isDetailedViewActive, setIsDetailedViewActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [timeFilter, setTimeFilter] = useState('week');
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [overtimeRate, setOvertimeRate] = useState('1x');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [timesheetToDelete, setTimesheetToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState('summary');
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [pdfOption, setPdfOption] = useState('summary');
  const [selectedEmployeeForPDF, setSelectedEmployeeForPDF] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [hourlyRates, setHourlyRates] = useState({});
  const [totalPay, setTotalPay] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // New state variables for PDF options
  const [pdfDateRange, setPdfDateRange] = useState([null, null]);
  const [includeDetailedLogs, setIncludeDetailedLogs] = useState(false);

  // Fetch timesheets from Firestore in real-time
  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(
      collection(db, 'timesheets'),
      (querySnapshot) => {
        const timeSheetsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTimeSheets(timeSheetsData);
        setFilteredTimeSheets(timeSheetsData);
        setIsLoading(false);
      },
      (error) => {
        console.error('Error fetching timesheets:', error);
        toast.error('Failed to fetch timesheets.');
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  // Fetch hourly rates from employeePay collection in real-time
  useEffect(() => {
    const employeePayRef = collection(db, 'employeePay');
    const unsubscribe = onSnapshot(
      employeePayRef,
      (snapshot) => {
        const rates = {};
        snapshot.forEach((doc) => {
          rates[doc.id] = doc.data().hourlyRate;
        });
        setHourlyRates(rates);
      },
      (error) => {
        console.error('Error fetching employee pay:', error);
        toast.error('Failed to fetch employee pay data.');
      }
    );

    return () => unsubscribe();
  }, []);

  const logDates = useMemo(() => {
    return [...new Set(timeSheets.map((ts) => ts.logDate).filter(Boolean))]
      .map((dateStr) => parseDateSafe(dateStr))
      .filter((date) => date !== null);
  }, [timeSheets]);

  const highlightWithClassName = (date) => {
    const currentDate = new Date();
    if (isSameDay(date, currentDate))
      return 'react-datepicker__day--highlighted-current-day'; // Current day class
    if (logDates.some((logDate) => isSameDay(logDate, date)))
      return 'react-datepicker__day--highlighted-log-day'; // Log day class
    return null;
  };

  // Memoize getTimeRange
  const getTimeRange = useCallback(() => {
    const now = new Date();
    switch (timeFilter) {
      case 'week':
        return [startOfWeek(now), endOfWeek(now)];
      case 'month':
        return [startOfMonth(now), endOfMonth(now)];
      case 'year':
        return [startOfYear(now), endOfYear(now)];
      case 'custom':
        return dateRange;
      case 'all':
        return [null, null];
      default:
        return [null, null];
    }
  }, [timeFilter, dateRange]);

  const calculateHoursWorked = (timesheet) => {
    const { clockIn, clockOut, workedHours = null, breakTime = 0 } = timesheet;

    // Return manually entered hours if provided
    if (workedHours !== null && workedHours !== undefined) {
        const parsedWorkedHours = parseFloat(workedHours) || 0;
        const breakHours = breakTime / 60;
        return parsedWorkedHours - breakHours;
    }

    // Convert clockIn and clockOut to 24-hour format
    const clockInTime = convertTo24HourTime(clockIn);
    const clockOutTime = convertTo24HourTime(clockOut);

    // Ensure both clockInTime and clockOutTime are valid
    if (!clockInTime || !clockOutTime) return 0;

    // Calculate minutes for each time
    const clockInMinutes = clockInTime.hours * 60 + clockInTime.minutes;
    const clockOutMinutes = clockOutTime.hours * 60 + clockOutTime.minutes;

    let totalMinutes;

    // Check if clock-out time is the same as clock-in but on the next day
    if (clockIn === clockOut) {
        // Assume 24 hours worked if clock-in and clock-out times are identical
        totalMinutes = 24 * 60;
    } else if (clockOutMinutes < clockInMinutes) {
        // Clock-out is on the next day
        totalMinutes = (24 * 60 - clockInMinutes) + clockOutMinutes;
    } else {
        // Clock-out is on the same day
        totalMinutes = clockOutMinutes - clockInMinutes;
    }

    const totalHours = totalMinutes / 60;
    const breakHours = breakTime / 60;

    return totalHours - breakHours;
};


  const handleOvertimeRateChange = (newRate) => {
    setOvertimeRate(newRate);
    toast.info(`Overtime rate changed to ${newRate}`);
  };

  const aggregateEmployeeData = useCallback(
    (timesheets) => {
      const employeeData = {};
      timesheets.forEach((timesheet) => {
        const employeeName = timesheet.employeeName;

        const ratePerHour =
          hourlyRates[employeeName] !== undefined
            ? parseFloat(hourlyRates[employeeName]) || 0
            : parseFloat(timesheet.ratePerHour) || 0;

        if (!employeeData[employeeName]) {
          employeeData[employeeName] = {
            totalHours: 0,
            totalOvertime: 0,
            ratePerHour: ratePerHour,
            totalPay: 0,
          };
        }

        const hoursWorked = calculateHoursWorked(timesheet);
        const overtimeHours = parseFloat(timesheet.overtime) || 0;
        const overtimeMultiplier = parseFloat(overtimeRate.replace('x', '')) || 1;

        const payForOvertime = overtimeHours * overtimeMultiplier * ratePerHour;

        employeeData[employeeName].totalHours += hoursWorked;
        employeeData[employeeName].totalOvertime += overtimeHours;
        employeeData[employeeName].totalPay +=
          hoursWorked * ratePerHour + payForOvertime;
        employeeData[employeeName].ratePerHour = ratePerHour;
      });

      return Object.keys(employeeData).map((employeeName) => ({
        employeeName,
        totalHours: employeeData[employeeName].totalHours.toFixed(2),
        totalOvertime: parseFloat(
          employeeData[employeeName].totalOvertime
        ).toFixed(2),
        totalPay: employeeData[employeeName].totalPay.toFixed(2),
        ratePerHour: employeeData[employeeName].ratePerHour.toFixed(2),
      }));
    },
    [hourlyRates, overtimeRate]
  );

  // Filter Functions
  const filterTimesheetsByDateRange = useCallback(
    (timesheets) => {
      const [startDate, endDate] = getTimeRange();
      if (!startDate || !endDate) return timesheets;
      return timesheets.filter((timesheet) => {
        const logDate = timesheet.logDate ? parseDateSafe(timesheet.logDate) : null;
        return logDate && logDate >= startDate && logDate <= endDate;
      });
    },
    [getTimeRange]
  );

  const filterByEmployee = useCallback(
    (timesheets) => {
      if (employeeFilter) {
        return timesheets.filter((ts) =>
          ts.employeeName.toLowerCase().includes(employeeFilter.toLowerCase())
        );
      }
      return timesheets;
    },
    [employeeFilter]
  );

  // Update filteredTimeSheets based on filters
  useEffect(() => {
    const filteredByEmployee = filterByEmployee(timeSheets);
    const filteredByDateRange = filterTimesheetsByDateRange(filteredByEmployee);
    setFilteredTimeSheets(filteredByDateRange);
  }, [
    timeSheets,
    employeeFilter,
    timeFilter,
    dateRange,
    filterByEmployee,
    filterTimesheetsByDateRange,
  ]);

  // Aggregated Data
  const aggregatedData = useMemo(() => {
    return aggregateEmployeeData(filteredTimeSheets);
  }, [filteredTimeSheets, aggregateEmployeeData]);

  // Use Effect for Total Pay
  useEffect(() => {
    const newTotalPay = {};
    aggregatedData.forEach((employee) => {
      const hoursWorked = parseFloat(employee.totalHours);
      const overtimeHours = parseFloat(employee.totalOvertime);
      const hourlyRate =
        parseFloat(hourlyRates[employee.employeeName] || employee.ratePerHour) || 0;
      const overtimeMultiplier = parseFloat(overtimeRate.replace('x', '')) || 1;

      newTotalPay[employee.employeeName] =
        hoursWorked * hourlyRate +
        overtimeHours * overtimeMultiplier * hourlyRate;
    });
    setTotalPay(newTotalPay);
  }, [aggregatedData, hourlyRates, overtimeRate]);

  const handleExportExcel = () => {
    const data = aggregatedData;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Timesheets');
    XLSX.writeFile(workbook, 'timesheets.xlsx');
    toast.success('Exported to Excel successfully!');
  };

  const handleExportCSV = () => {
    const data = aggregatedData;
    const csvData = XLSX.utils.json_to_sheet(data);
    const csvContent = XLSX.utils.sheet_to_csv(csvData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'timesheets.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success('Exported to CSV successfully!');
  };

  const handleDeleteEmployee = async (employeeName) => {
    try {
      const filteredTimesheets = timeSheets.filter(
        (ts) => ts.employeeName === employeeName
      );
      for (const timesheet of filteredTimesheets) {
        await deleteDoc(doc(db, 'timesheets', timesheet.id));
      }
      // Also delete from employeePay collection
      await deleteDoc(doc(db, 'employeePay', employeeName));
      setTimeSheets((prev) =>
        prev.filter((sheet) => sheet.employeeName !== employeeName)
      );
      toast.success(`Deleted timesheets and pay data for ${employeeName}`);
    } catch (error) {
      console.error(`Error deleting timesheets for ${employeeName}:`, error);
      toast.error(`Failed to delete timesheets for ${employeeName}.`);
    }
  };

  const handleDeleteSingleTimesheet = async (timesheet) => {
    try {
      await deleteDoc(doc(db, 'timesheets', timesheet.id));
      setTimeSheets((prev) => prev.filter((sheet) => sheet.id !== timesheet.id));
      toast.success(`Deleted timesheet for ${timesheet.employeeName}`);
    } catch (error) {
      console.error('Error deleting timesheet:', error);
      toast.error('Failed to delete timesheet.');
    }
  };

  useEffect(() => {
    setIsDetailedViewActive(activeTab === 'detailed');
  }, [activeTab]);

  const handleDelete = async () => {
    if (!timesheetToDelete) return;
    try {
      if (typeof timesheetToDelete === 'string') {
        await handleDeleteEmployee(timesheetToDelete);
      } else {
        await handleDeleteSingleTimesheet(timesheetToDelete);
      }
      setDeleteModal(false);
      setTimesheetToDelete(null);
    } catch (error) {
      console.error('Error deleting timesheet:', error);
      toast.error('Failed to delete timesheet.');
    }
  };

  const handleEditClick = (timesheet) => {
    const [clockInHour, clockInMinute, clockInPeriod] = convertTo12HourTime(
      timesheet.clockIn
    );
    const [clockOutHour, clockOutMinute, clockOutPeriod] = convertTo12HourTime(
      timesheet.clockOut
    );

    setSelectedTimesheet({
      ...timesheet,
      clockInHour: clockInHour || '',
      clockInMinute: clockInMinute || '',
      clockInPeriod: clockInPeriod || 'AM',
      clockOutHour: clockOutHour || '',
      clockOutMinute: clockOutMinute || '',
      clockOutPeriod: clockOutPeriod || 'AM',
    });
    setShowEditModal(true);
  };

  const handleSaveChanges = async () => {
    if (!selectedTimesheet) return;

    // Construct the updated times
    const updatedClockIn =
      selectedTimesheet.clockInHour && selectedTimesheet.clockInMinute
        ? `${selectedTimesheet.clockInHour}:${selectedTimesheet.clockInMinute} ${selectedTimesheet.clockInPeriod}`
        : selectedTimesheet.clockIn;

    const updatedClockOut =
      selectedTimesheet.clockOutHour && selectedTimesheet.clockOutMinute
        ? `${selectedTimesheet.clockOutHour}:${selectedTimesheet.clockOutMinute} ${selectedTimesheet.clockOutPeriod}`
        : selectedTimesheet.clockOut;

    const updatedTimesheet = {
      ...selectedTimesheet,
      clockIn: updatedClockIn,
      clockOut: updatedClockOut,
    };

    // Calculate hours worked and total pay
    const hoursWorked = calculateHoursWorked(updatedTimesheet);
    const overtimeMultiplier = parseFloat(overtimeRate.replace('x', '')) || 1;
    const ratePerHour =
      parseFloat(
        hourlyRates[selectedTimesheet.employeeName] || selectedTimesheet.ratePerHour
      ) || 0;
    const payForOvertime =
      (parseFloat(selectedTimesheet.overtime) || 0) *
      overtimeMultiplier *
      ratePerHour;
    const totalPayCalculated = (
      hoursWorked * ratePerHour +
      payForOvertime
    ).toFixed(2);

    try {
      const timesheetDocRef = doc(db, 'timesheets', selectedTimesheet.id);
      await updateDoc(timesheetDocRef, {
        ...updatedTimesheet,
        hoursWorked: hoursWorked.toFixed(2),
        totalPay: parseFloat(totalPayCalculated),
      });

      // Update local state with the new timesheet values
      setTimeSheets((prev) =>
        prev.map((ts) =>
          ts.id === selectedTimesheet.id
            ? {
                ...updatedTimesheet,
                hoursWorked: hoursWorked.toFixed(2),
                totalPay: parseFloat(totalPayCalculated),
              }
            : ts
        )
      );

      setShowEditModal(false);
      toast.success('Timesheet updated successfully!');
    } catch (error) {
      console.error('Error updating timesheet:', error);
      toast.error('Failed to update timesheet.');
    }
  };

  // Handle Hourly Rate Change
  const handleHourlyRateChange = useCallback((employeeName, value) => {
    // Update the hourly rate in local state
    setHourlyRates((prev) => ({
      ...prev,
      [employeeName]: value,
    }));
  }, []);

  // Save hourly rate when input loses focus
  const handleHourlyRateBlur = useCallback(
    async (employeeName) => {
      const rate = hourlyRates[employeeName];
      if (rate !== undefined && rate !== '') {
        try {
          const employeeDocRef = doc(db, 'employeePay', employeeName);
          await setDoc(
            employeeDocRef,
            { hourlyRate: parseFloat(rate) },
            { merge: true }
          );
          toast.success(`Saved hourly rate for ${employeeName}`);
        } catch (error) {
          console.error('Error saving hourly rate:', error);
          toast.error('Failed to save hourly rate.');
        }
      }
    },
    [hourlyRates]
  );

  // Unique Employees for Filter
  const uniqueEmployees = useMemo(() => {
    const employeesSet = new Set();
    timeSheets.forEach((ts) => {
      employeesSet.add(ts.employeeName);
    });
    return Array.from(employeesSet);
  }, [timeSheets]);

  const DetailedTimesheetView = () => {
    const selectedDateStr = format(selectedDate, 'yyyy-MM-dd');
    const filteredData = filteredTimeSheets.filter((timesheet) => {
      const logDate = normalizeDate(timesheet.logDate);
      return logDate === selectedDateStr;
    });

    const totalHours = filteredData
      .reduce((sum, ts) => sum + calculateHoursWorked(ts), 0)
      .toFixed(2);

    return (
      <div>
        {isLoading ? (
          <Row className="justify-content-center">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
          <Table striped bordered hover responsive>
            <thead style={{ backgroundColor: '#343a40', color: '#fff' }}>
              <tr>
                <th>Employee Name</th>
                <th>Time In / Out</th>
                <th>Break Time (min)</th>
                <th>Overtime (hrs)</th>
                <th>Total Hours Worked</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
  {filteredData.map((timesheet) => {
    const hoursWorked = calculateHoursWorked(timesheet);
    const formattedDate = timesheet.logDate
      ? format(parseDateSafe(timesheet.logDate), 'MM-dd-yyyy')
      : 'N/A';

    return (
      <tr key={timesheet.id}>
        <td>{timesheet.employeeName}</td>
        <td>
          <div className='mb-1'>
          <strong>Log Date: </strong> {formattedDate}
          </div>
          {timesheet.clockIn && timesheet.clockOut ? (
            <>
              <div>
                <strong>Clock In:</strong> {timesheet.clockIn}
              </div>
              <div>
                <strong>Clock Out:</strong> {timesheet.clockOut}
              </div>
            </>
          ) : timesheet.workedHours !== null &&
            timesheet.workedHours !== undefined ? (
            'Manual Entry'
          ) : (
            'N/A'
          )}
        </td>
        <td>{timesheet.breakTime || '0'} min</td>
        <td>{timesheet.overtime || '0'} hrs</td>
        <td>{hoursWorked.toFixed(2)} hrs</td>
        <td>
          <Button
            variant="primary"
            size="md"
            className="me-2 internal-buttons"
            onClick={() => {
              handleEditClick(timesheet);
            }}
            aria-label={`Edit timesheet for ${timesheet.employeeName}`}
          >
            <span className="square-button">Edit</span>
          </Button>
          <Button
            variant="danger"
            className="me-2 internal-delete-buttons"
            onClick={() => {
              setTimesheetToDelete(timesheet);
              setDeleteModal(true);
            }}
            aria-label={`Delete timesheet for ${timesheet.employeeName}`}
          >
            <FontAwesomeIcon icon={faTrash} color="white" />
            <span className="ml-2 square-button">Delete</span>
          </Button>
        </td>
      </tr>
    );
  })}
</tbody>

            <tfoot style={{ fontWeight: 'bold' }}>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalHours} hrs</td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        )}
      </div>
    );
  };

  // Unified PDF Generation Function
  const generatePDF = ({
    pdfOption,
    selectedEmployee,
    notes,
    dateRange,
    includeDetailedLogs,
  }) => {
    const [startDate, endDate] = dateRange;
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

    // Filter timesheets based on date range and employee
    let filteredTimesheets = timeSheets.filter((ts) => {
      const logDate = ts.logDate ? parseDateSafe(ts.logDate) : null;
      const withinDateRange =
        !startDate ||
        !endDate ||
        (logDate && logDate >= startDate && logDate <= endDate);

      if (pdfOption === 'individual' && selectedEmployee) {
        return ts.employeeName === selectedEmployee && withinDateRange;
      } else {
        return withinDateRange;
      }
    });

    // Aggregate data
    const employeeData = {};
    filteredTimesheets.forEach((timesheet) => {
      const employeeName = timesheet.employeeName;
      const ratePerHour =
        hourlyRates[employeeName] !== undefined
          ? parseFloat(hourlyRates[employeeName]) || 0
          : parseFloat(timesheet.ratePerHour) || 0;

      if (!employeeData[employeeName]) {
        employeeData[employeeName] = {
          totalHours: 0,
          totalOvertime: 0,
          ratePerHour: ratePerHour,
          totalPay: 0,
          timesheets: [],
        };
      }

      const hoursWorked = calculateHoursWorked(timesheet);
      const overtimeHours = parseFloat(timesheet.overtime) || 0;
      const overtimeMultiplier = parseFloat(overtimeRate.replace('x', '')) || 1;

      const payForOvertime = overtimeHours * overtimeMultiplier * ratePerHour;

      employeeData[employeeName].totalHours += hoursWorked;
      employeeData[employeeName].totalOvertime += overtimeHours;
      employeeData[employeeName].totalPay +=
        hoursWorked * ratePerHour + payForOvertime;
      employeeData[employeeName].ratePerHour = ratePerHour;
      employeeData[employeeName].timesheets.push({
        ...timesheet,
        hoursWorked,
      });
    });

    const aggregatedData = Object.keys(employeeData).map((employeeName) => ({
      employeeName,
      totalHours: employeeData[employeeName].totalHours.toFixed(2),
      totalOvertime: parseFloat(
        employeeData[employeeName].totalOvertime
      ).toFixed(2),
      totalPay: employeeData[employeeName].totalPay.toFixed(2),
      ratePerHour: employeeData[employeeName].ratePerHour.toFixed(2),
      timesheets: employeeData[employeeName].timesheets,
    }));

    // Prepare company information
    const companyInfo = {
      name: 'Imagine Seniorcare Inc.',
      email: 'imaginescinc@gmail.com',
      phone: '(831)-332-5053',
      website: 'imagineseniorcareinc.com',
      address: '255 El Camino Real #204, Burlingame, CA 94010',
    };

    if (pdfOption === 'individual' && selectedEmployee) {
      // Generate Individual Employee PDF
      const employee = aggregatedData.find(
        (emp) => emp.employeeName === selectedEmployee
      );
      if (!employee) {
        toast.error('Employee data not found.');
        return;
      }

      const timesheetData = {
        startDate: formattedStartDate || 'N/A',
        endDate: formattedEndDate || 'N/A',
        entries: employee.timesheets.map((ts) => ({
          date: ts.logDate
            ? format(parseDateSafe(ts.logDate), 'yyyy-MM-dd')
            : 'N/A',
          clockIn: ts.clockIn || 'N/A',
          clockOut: ts.clockOut || 'N/A',
          breakTime: ts.breakTime || 0,
          overtime: ts.overtime || 0,
          totalHours: ts.hoursWorked,
        })),
        totalHours: parseFloat(employee.totalHours),
        totalOvertime: parseFloat(employee.totalOvertime),
        hourlyRate: parseFloat(employee.ratePerHour) || 0,
        totalPay: parseFloat(employee.totalPay) || 0,
      };

      // Prepare parameters for the PDF function
      const params = {
        companyInfo,
        timesheetData,
        employeeName: employee.employeeName,
        notes,
        overtimeRate,
        additionalNotes: notes,
        includeDetailedLogs,
      };

      // Generate the PDF using the external function
      generateTimesheetPDF(params);

      toast.success(`PDF generated for ${employee.employeeName} successfully!`);
    } else {
      // Generate Summary PDF
      const timesheetData = {
        startDate: formattedStartDate || 'N/A',
        endDate: formattedEndDate || 'N/A',
        entries: [], // For summary, entries will be empty unless including detailed logs
      };

      // Prepare parameters for the PDF function
      const params = {
        companyInfo,
        aggregatedData,
        timesheetData,
        notes,
        overtimeRate,
        additionalNotes: notes,
        includeDetailedLogs,
      };

      // Generate the PDF using the external function
      generateSummaryPDF(params);

      toast.success('PDF Summary generated successfully!');
    }
  };

  return (
    <Container fluid className="timecard-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // Customize toast appearance to match Dashboard's design
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />

      <Row
        className="align-items-center mb-3"
        style={{ justifyContent: 'flex-end' }}
      >
        <Col>
          <h1
            className="timecard-title"
            style={{ marginBottom: '20px', color: '#343a40' }}
          >
            Timesheet Management
          </h1>
        </Col>
        {isDetailedViewActive && (
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <InputGroup className="me-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendarAlt} color="black" />
              </InputGroup.Text>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MM/dd/yyyy"
                className="form-control no-rounded-left"
                placeholderText="Select Date"
                aria-label="Select Date"
                dayClassName={highlightWithClassName}
              />
            </InputGroup>
          </Col>
        )}
      </Row>

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="timeSheets-tabs mb-3"
        justify
      >
        <Tab eventKey="summary" title="Summary View" className="nav-item">
          <SummaryView
            aggregatedData={aggregatedData}
            hourlyRates={hourlyRates}
            handleHourlyRateChange={handleHourlyRateChange}
            handleHourlyRateBlur={handleHourlyRateBlur}
            totalPay={totalPay}
            handleExportExcel={handleExportExcel}
            handleExportCSV={handleExportCSV}
            handleOvertimeRateChange={handleOvertimeRateChange}
            overtimeRate={overtimeRate}
            uniqueEmployees={uniqueEmployees}
            employeeFilter={employeeFilter}
            setEmployeeFilter={setEmployeeFilter}
            timeFilter={timeFilter}
            setTimeFilter={setTimeFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleDeleteEmployee={(employeeName) => {
              setTimesheetToDelete(employeeName);
              setDeleteModal(true);
            }}
            setShowPDFModal={setShowPDFModal}
            isLoading={isLoading}
          />
        </Tab>
        <Tab eventKey="detailed" title="Timesheet View" className="nav-item">
          <DetailedTimesheetView />
        </Tab>
      </Tabs>

      {/* Edit Timesheet Modal */}
      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header className="employee-log-modal-header" closeButton>
        <Modal.Title className="employee-log-modal-title">Edit Timesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          {selectedTimesheet && (
            <Form>
              {selectedTimesheet.workedHours !== null &&
              selectedTimesheet.workedHours !== undefined ? (
                // Manual Entry Display
                <Row className="compact-row mt-2">
                  <Col>
                    <h6 className="compact-label modal-body-text">Manual Entry</h6>
                    <Form.Control
                      type="number"
                      value={selectedTimesheet.workedHours}
                      onChange={(e) =>
                        setSelectedTimesheet({
                          ...selectedTimesheet,
                          workedHours: e.target.value,
                        })
                      }
                      placeholder="Enter hours worked manually"
                      min="0"
                      step="0.01"
                      className="compact-input"
                    />
                  </Col>
                </Row>
              ) : (
                // Clock In/Out Time Pickers
                <>
                  <Row className="compact-row mt-2">
                    <Col>
                      <h6 className="compact-label modal-body-text">Clock In Time</h6>
                      <div className="compact-time-inputs">
                        <Form.Select
                          value={selectedTimesheet.clockInHour || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockInHour: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            Hour
                          </option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={selectedTimesheet.clockInMinute || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockInMinute: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            Minute
                          </option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Select>
                        <Form.Select
                          value={selectedTimesheet.clockInPeriod || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockInPeriod: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            AM/PM
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Form.Select>
                      </div>
                    </Col>

                    <Col>
                      <h6 className="compact-label modal-body-text">
                        Clock Out Time
                      </h6>
                      <div className="compact-time-inputs">
                        <Form.Select
                          value={selectedTimesheet.clockOutHour || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockOutHour: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            Hour
                          </option>
                          {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={selectedTimesheet.clockOutMinute || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockOutMinute: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            Minute
                          </option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Select>
                        <Form.Select
                          value={selectedTimesheet.clockOutPeriod || ''}
                          onChange={(e) =>
                            setSelectedTimesheet({
                              ...selectedTimesheet,
                              clockOutPeriod: e.target.value,
                            })
                          }
                          className="time-select"
                        >
                          <option value="" disabled>
                            AM/PM
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {/* Break Time & Overtime Fields */}
              <Row className="compact-row mt-3">
                <Col>
                  <h6 className="compact-label modal-body-text">Break Time (min)</h6>
                  <Form.Control
                    type="number"
                    value={selectedTimesheet.breakTime || ''}
                    onChange={(e) =>
                      setSelectedTimesheet({
                        ...selectedTimesheet,
                        breakTime: e.target.value,
                      })
                    }
                    className="compact-input"
                    placeholder="Break Time"
                    min="0"
                  />
                </Col>
                <Col>
                  <h6 className="compact-label modal-body-text">Overtime (hours)</h6>
                  <Form.Control
                    type="number"
                    value={selectedTimesheet.overtime || ''}
                    onChange={(e) =>
                      setSelectedTimesheet({
                        ...selectedTimesheet,
                        overtime: e.target.value,
                      })
                    }
                    className="compact-input"
                    placeholder="Overtime"
                    min="0"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* PDF Options Modal */}
      <Modal
        size="lg"
        show={showPDFModal}
        onHide={() => setShowPDFModal(false)}
        centered
      >
        <Modal.Header className="employee-log-modal-header" closeButton>
        <Modal.Title className="employee-log-modal-title">Download PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form>
            <Form.Group controlId="pdfOption" className="mb-3">
              <Form.Label>Select PDF Type</Form.Label>
              <Form.Check
                type="radio"
                label="Summary PDF"
                name="pdfOption"
                id="summaryPDF"
                value="summary"
                checked={pdfOption === 'summary'}
                onChange={(e) => setPdfOption(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Individual Employee PDF"
                name="pdfOption"
                id="individualPDF"
                value="individual"
                checked={pdfOption === 'individual'}
                onChange={(e) => setPdfOption(e.target.value)}
              />
            </Form.Group>

            {pdfOption === 'individual' && (
              <Form.Group controlId="selectEmployee" className="mb-3">
                <Form.Label>Select Employee</Form.Label>
                <Form.Select
                  value={selectedEmployeeForPDF}
                  onChange={(e) => setSelectedEmployeeForPDF(e.target.value)}
                  aria-label="Select Employee"
                >
                  <option value="">-- Select Employee --</option>
                  {aggregatedData.map((employee) => (
                    <option
                      key={employee.employeeName}
                      value={employee.employeeName}
                    >
                      {employee.employeeName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group controlId="dateRange" className="mb-3">
              <Form.Label>Select Time Period</Form.Label>
              <Row>
                <Col>
                  <DatePicker
                    selected={pdfDateRange[0]}
                    onChange={(startDate) =>
                      setPdfDateRange([startDate, pdfDateRange[1]])
                    }
                    selectsStart
                    startDate={pdfDateRange[0]}
                    endDate={pdfDateRange[1]}
                    isClearable
                    className="form-control"
                    placeholderText="Start Date"
                    aria-label="Start Date"
                  />
                </Col>
                <Col>
                  <DatePicker
                    selected={pdfDateRange[1]}
                    onChange={(endDate) =>
                      setPdfDateRange([pdfDateRange[0], endDate])
                    }
                    selectsEnd
                    startDate={pdfDateRange[0]}
                    endDate={pdfDateRange[1]}
                    isClearable
                    className="form-control"
                    placeholderText="End Date"
                    aria-label="End Date"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="includeDetails" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Include Detailed Logs"
                checked={includeDetailedLogs}
                onChange={(e) => setIncludeDetailedLogs(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId="additionalNotes" className="mb-3">
              <Form.Label>Additional Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={additionalNotes}
                onChange={(e) => setAdditionalNotes(e.target.value)}
                placeholder="Enter any additional notes to include in the PDF..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPDFModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (
                pdfOption === 'summary' ||
                (pdfOption === 'individual' && selectedEmployeeForPDF)
              ) {
                generatePDF({
                  pdfOption,
                  selectedEmployee: selectedEmployeeForPDF,
                  notes: additionalNotes,
                  dateRange: pdfDateRange,
                  includeDetailedLogs,
                });
              } else {
                toast.error('Please select an employee for Individual PDF.');
                return;
              }
              setShowPDFModal(false);
              setAdditionalNotes('');
              setSelectedEmployeeForPDF('');
              setPdfDateRange([null, null]);
              setIncludeDetailedLogs(false);
            }}
          >
            Generate PDF
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} >
      <Modal.Header closeButton className="employee-log-modal-title">
      <Modal.Title className="employee-log-modal-title">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          {typeof timesheetToDelete === 'string' ? (
            <span>
              Are you sure you want to delete all timesheets for{' '}
              <strong>{timesheetToDelete}</strong>?
            </span>
          ) : (
            <span>
              Are you sure you want to delete the timesheet for{' '}
              <strong>{timesheetToDelete?.employeeName}</strong> on{' '}
              <strong>{timesheetToDelete?.logDate}</strong>?
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TimeSheets;